// @flow
import style from "./style.module.scss";

//import libs
import React, {
    useEffect,
    useState,
    Fragment,
    useMemo,
    useCallback,
} from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import StarIcon from "@material-ui/icons/Star";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch } from "react-redux";
import { load as loadTeamsAction } from "@stores/get-teams";

import { usePermission, useMember } from "@hooks";
import {
    isArrayWithContent,
    deleteAttachmentFromFe,
    isComplaint,
} from "@utils";
// components
import {
    Typography,
    ReasonsPanel,
    InspectionPointInfo,
    DataRenderer,
    LoadingBox,
    LoadingButton,
    TopBar,
    PersonsModal,
    Table,
    SimpleExpansionPanel,
    VisitCard,
    RequestPersonsInfo,
} from "@components/Shared";
import AddVisitModal from "../Assignment/AddVisitModal";
import EditDetails from "./EditDetails";

import {
    NOTIFICATIONS,
    REQUESTS,
    PROCESS_STATUSES,
    ROWRENDERERCONST,
    ASSIGNMENTS,
} from "@constants";
import type { Person, ApiStore } from "@types";

import { finalReport as finalReportDefinition } from "../../definitions";
import { useUserRoleType } from "../../hooks/useUserRoleType";

const possibleReportsStatuses = [
    REQUESTS.STATUSES.INCLUDED_IN_INSPECTION,
    REQUESTS.STATUSES.CLOSED,
];
const canHaveReports = (status: string) =>
    possibleReportsStatuses.includes(status);

/**
 * Prop types
 */
type Props = {
    id: string,
    user: *,
    requestValues?: *,
    requestId: string,
    getRequest: (id: string) => void,
    clearRequest: () => void,
    updateRequest?: (id: string, data: *) => Promise<*>,
    notify?: (notification: *) => void,
    location: *,
    loading: boolean,
    //DropZone props
    uploadAttachments: (requestId: string, files: Array<*>) => Promise<*>,
    uploaderValue?: *,
    downloadDocument: (file: *) => void,
    clearAttachments?: () => void,
    deleteRequestAttachment: (requestId: string, documentId: string) => void,
    attachments: Array<*>,
    updateFiles: (files: *) => void,
    reasons: *,
    loadReasons: () => void,
    reasonsLoading: boolean,
    getFunctionalEntities: *,
    loadFunctionalEntitiesList: () => void,
    loadFeUsers: () => void,
    getFeUsers: *,
    clearUpdateRequest: () => void,
    updateAssignee: ApiStore<*>,
    updateValidator: ApiStore<*>,
    assignInspector: (string, Person) => Promise<*>,
    assignValidator: (string, Person) => Promise<*>,
    uploaderLoading?: boolean,
    loadReports: (inspectionId: string) => void,
    reportsStore: ApiStore<*>,
    addRequestVisits: (requestId: string, data: *) => Promise<*>,
    removeRequestVisit: (requestId: string, inspectionPointId: string) => void,
};

/**
 * Select Dates
 */
const RequestDetails = ({
    clearAttachments,
    deleteRequestAttachment,
    downloadDocument,
    getRequest,
    clearRequest,
    id,
    loading,
    notify,
    requestValues,
    requestId,
    updateFiles,
    updateRequest,
    uploadAttachments,
    uploaderValue,
    uploaderLoading,
    //user,
    loadReasons,
    reasons,
    reasonsLoading,
    getFunctionalEntities,
    loadFunctionalEntitiesList,
    loadFeUsers,
    clearUpdateRequest,
    getFeUsers,
    updateAssignee,
    updateValidator,
    assignInspector,
    assignValidator,
    user,
    loadReports,
    reportsStore,
    addRequestVisits,
    removeRequestVisit,
}: Props) => {
    //toggle modal
    const [isEditOpen, toggleEditModal] = useState(false);
    const [personModal, togglePersonModal] = useState("none");
    const [showVisitModal, toggleVisitModal] = useState(false);
    const [item, setItem] = useState(undefined);
    const dispatch = useDispatch();

    // authorization hooks
    const hasPermissionToEdit = usePermission(
        "requestDetails.edit.write",
        requestValues?.status,
    );
    const hasPermissionToAssignValidator = usePermission(
        "requestDetails.validator.write",
        requestValues?.status,
    );
    const hasPermissionToViewAssignee = usePermission(
        "requestDetails.assignee.read",
        requestValues?.status,
    );
    const hasPermissionToAssignLeadInspector = usePermission(
        "requestDetails.leadInspector.write",
        requestValues?.status,
    );
    const hasPermissionToUploadAttachments = usePermission(
        "requestDetails.attachments.add.write",
        requestValues?.status,
    );
    const hasPermissionToDeleteAttachments = usePermission(
        "requestDetails.attachments.delete.write",
    );
    const hasPermissionToVisitLinkedAssignment = usePermission(
        "requestDetails.assignmentLink.read",
    );
    const hasPermissionToEditExternalReference = usePermission(
        "requestDetails.editExternalReference.write",
    );
    const hasPermissionToDownloadReports = usePermission(
        "requestDetails.downloadReports.write",
    );

    //check if the user is the original creator/requestor of the request
    const userIsRequestor = user?.sub === requestValues?.feContactId;

    const userIsMember = useMember("request");

    const canEdit =
        hasPermissionToEdit ||
        userIsMember ||
        hasPermissionToEditExternalReference ||
        userIsRequestor;

    const isQualityControl = [
        ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
        ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
    ].includes(requestValues?.status);

    const canUploadAttachments =
        hasPermissionToUploadAttachments || userIsMember || isQualityControl;
    const canDeleteAttachments =
        hasPermissionToDeleteAttachments || userIsMember || isQualityControl;
    const canDownloadReports = hasPermissionToDownloadReports || userIsMember;

    const userCanEditVisits = useUserRoleType([
        "ROLE_ADMIN",
        "ROLE_MINIADMIN",
        "ROLE_SECRETARIAT",
    ]);
    const statusCanEditVisits = [
        REQUESTS.STATUSES.CREATED,
        REQUESTS.STATUSES.VALIDATION_REQUESTED,
        REQUESTS.STATUSES.VALIDATED,
    ].includes(requestValues?.status);
    const canEditBezoek = !!(userCanEditVisits && statusCanEditVisits);

    const reportRows = useMemo(
        () =>
            isArrayWithContent(reportsStore.data) &&
            requestValues &&
            requestValues?.inspectionId
                ? reportsStore.data.filter(report => report.type === "FINAL")
                : [],
        [reportsStore.data, requestValues?.inspectionId],
    );
    const teamsStoreSelector = state => state.getTeams;
    const teamsStore = useSelector(teamsStoreSelector);
    const getTeams = useCallback(() => dispatch(loadTeamsAction()), [dispatch]);

    // it's important to clear uploaderValue.uploaded
    // the  reason is when we  are creating a new request
    // and we already uploaded some attachments through Data-aanvullen page
    // and suddenly we go back or stop the progress and visit aanvraag-details page
    // when we update the request the previous attachments and the new all together will be sent to BE
    useEffect(() => {
        if (requestValues && clearAttachments) {
            clearAttachments();
        }
        if (requestValues && requestValues.id !== requestId) {
            clearRequest();
        }
        if (
            (!requestValues || requestId !== requestValues.id) &&
            requestId &&
            getRequest
        ) {
            getRequest(requestId);
        }
    }, [requestValues, requestId]); // hope this won't break anything, but for proper usage of hooks it's required

    useEffect(() => {
        if (
            requestValues &&
            requestValues?.inspectionId &&
            canHaveReports(requestValues.status)
        ) {
            loadReports(requestValues.inspectionId);
        } else {
            //clear reportsStore
            reportsStore.data = undefined;
        }
    }, [requestValues, loadReports, requestValues?.inspectionId]);

    useEffect(() => {
        getTeams();
    }, [getTeams]);

    // return early if there's nothing to show
    if (!requestValues && loading) return <LoadingBox />;
    else if (!requestValues) return null;

    const canAssignValidator =
        requestValues &&
        (hasPermissionToAssignValidator || userIsMember) &&
        [
            REQUESTS.STATUSES.CREATED,
            REQUESTS.STATUSES.VALIDATION_REQUESTED,
        ].some(status => requestValues.status === status);

    const canAssignLeadInspector =
        requestValues &&
        (hasPermissionToAssignLeadInspector || userIsMember) &&
        [
            REQUESTS.STATUSES.CREATED,
            REQUESTS.STATUSES.VALIDATION_REQUESTED,
            REQUESTS.STATUSES.VALIDATED,
        ].some(status => requestValues.status === status);

    /**
     * Update request
     */
    const handleRequestUpdate = (data: *) => {
        requestValues &&
            updateRequest &&
            updateRequest(requestValues.id, data).then(response => {
                response &&
                    notify &&
                    notify({
                        type: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: `Aanvraag is aangepast`,
                    });
                clearAttachments && clearAttachments();
                clearUpdateRequest();
                toggleEditModal(false);
            });
    };

    /**
     * Upload document
     */
    const handleUpload = (files: Array<*>, keyIndentifier: string) => {
        // upload to Frontend
        if (keyIndentifier !== "upload" || !requestValues) return;
        uploadAttachments(requestValues.id, files);
    };

    /**
     * Delete attachment from request
     */
    const deleteAttachment = (file: *, keyIndentifier: string) => {
        if (keyIndentifier === "deleteAttachmentFromFE") {
            deleteAttachmentFromFe(file.uuid, uploaderValue, updateFiles);
        }

        if (
            keyIndentifier === "deleteAttachmentFromAPI" &&
            requestValues &&
            notify
        ) {
            deleteRequestAttachment(requestValues.id, file.documentId);
        }
    };

    const handleSelectPerson = (modalType: string) => (_, person) => {
        if (modalType === "validator")
            assignValidator(requestValues.id, person);
        if (modalType === "inspector")
            assignInspector(requestValues.id, person);
    };

    const getSelectedPersonForModal = (type: string) => {
        const { status, assignee } = requestValues;
        if (type === "validator") return assignee ? [assignee] : null;
        if (type === "inspector") {
            return status === REQUESTS.STATUSES.VALIDATION_REQUESTED
                ? null
                : assignee
                ? [assignee]
                : null;
        }
        return null;
    };

    const handleDownloadReport = (filename: string, documentId: string) =>
        downloadDocument({ name: filename, documentId });

    /**
     * Click visit card header icon
     */
    const handleVisitIconClick = (visit: *, action: *) => {
        if (!!action && action === "delete") {
            notify &&
                notify({
                    type: NOTIFICATIONS.TYPE.MODAL,
                    severity: NOTIFICATIONS.SEVERITY.WARNING,
                    message: `Bent u zeker dat u dit bezoek wilt annuleren?`,
                    primaryAction: () =>
                        removeRequestVisit(requestValues.id, visit.id),
                    primaryActionText: "Ok",
                    secondaryAction: undefined,
                    secondaryActionText: "Annuleer",
                });
        }
        if (!!action && action === "update") {
            notify &&
                notify({
                    type: NOTIFICATIONS.TYPE.MODAL,
                    severity: NOTIFICATIONS.SEVERITY.WARNING,
                    message: (
                        <Fragment>
                            <Typography type="body1">
                                Bent u zeker dat u het hoofdbezoek wilt
                                vervangen?
                            </Typography>
                            <Typography type="body2" color="error">
                                Opgelet: alle andere inspectiepunten worden ook
                                verwijderd!
                            </Typography>
                        </Fragment>
                    ),
                    primaryAction: () =>
                        handleChangeMainVisit(requestValues.id),
                    primaryActionText: "Ok, zoek inspectiepunt",
                    secondaryAction: undefined,
                    secondaryActionText: "Annuleer",
                });
        }
    };
    const handleChangeMainVisit = (inspectionRequestId: *) => {
        const STORAGE_KEY = "updateMainVisit";
        const data = {
            inspectionRequestId: inspectionRequestId,
            type: "aanvraag",
        };

        // cleanup possible faulty old keys
        if (sessionStorage[STORAGE_KEY]) {
            sessionStorage.removeItem(STORAGE_KEY);
        }

        // set data to sesionStorage
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(data));

        navigate("inspectiepunt/zoek-inspectiepunt");
    };

    const handleAddRequestVisits = (visits: Array<*>) => {
        if (!isArrayWithContent(visits)) return;
        const data = visits.map(visit => ({
            inspectionPointId: visit.inspectionPointId,
            activityTypes: isArrayWithContent(visit.activityTypes)
                ? visit.activityTypes.map(type => type.id)
                : [],
        }));
        addRequestVisits(requestValues.requestId, data).then(() =>
            toggleVisitModal(false),
        );
    };

    const requestTeam =
        teamsStore.data &&
        teamsStore.data.find(team => team.id === requestValues.teamId);
    /**
     * Render
     */
    return (
        <Fragment>
            {requestValues && requestValues.reference && (
                <Helmet>
                    <title>{requestValues.reference}</title>
                </Helmet>
            )}
            {canEdit && (
                <EditDetails
                    id={id}
                    isOpen={isEditOpen}
                    toggleModal={() => toggleEditModal(!isEditOpen)}
                    item={item}
                    setItem={item => setItem(item)}
                    request={requestValues}
                    reasons={reasons}
                    reasonsLoading={reasonsLoading}
                    handleRequestUpdate={handleRequestUpdate}
                    getFunctionalEntities={getFunctionalEntities}
                    loadFunctionalEntitiesList={loadFunctionalEntitiesList}
                    loadReasons={loadReasons}
                    loadFeUsers={loadFeUsers}
                    getFeUsers={getFeUsers}
                    user={user}
                    teams={teamsStore.data}
                />
            )}

            <PersonsModal
                id={`${id}-personModal`}
                isOpen={personModal !== "none"}
                title={
                    personModal === "validator"
                        ? "Validator toewijzen"
                        : "Hoofdinspecteur toewijzen"
                }
                onClose={() => togglePersonModal("none")}
                personModalType="assignee"
                onSelect={handleSelectPerson(personModal)}
                callInProgress={updateAssignee.loading}
                selectedPersons={getSelectedPersonForModal(personModal)}
                inspectionPointId={requestValues?.inspectionPointId}
                showPills={!!requestValues?.assignee}
                onlyValidators={personModal === "validator"}
                onlyInspectors={personModal === "inspector"}
                single
            />
            <AddVisitModal
                id={`${id}-mdlAddVisits`}
                isOpen={showVisitModal}
                onSave={handleAddRequestVisits}
                onCancel={() => toggleVisitModal(false)}
                inspectionPointId={requestValues.inspectionPointId}
                inspectionPointType={
                    requestValues.inspectionPoints[0].inspectionPointType
                }
            />
            <Box display="flex" justifyContent="flex-end">
                {canEdit && (
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => toggleEditModal(true)}
                        id={`${id}-btnEdit-request`}
                        startIcon={<EditIcon fontSize="inherit" />}
                        className={style.buttonSpacer}
                    >
                        Aanpassen
                    </Button>
                )}
                {canAssignValidator && (
                    <LoadingButton
                        variant="outlined"
                        size="small"
                        id={`${id}-asssign-validator`}
                        className={style.buttonSpacer}
                        onClick={() => togglePersonModal("validator")}
                        loading={updateValidator.loading}
                        disabled={updateAssignee.loading}
                        icon={<PersonIcon />}
                    >
                        {requestValues.status === REQUESTS.STATUSES.CREATED
                            ? "Validator toewijzen"
                            : "Validator wijzigen"}
                    </LoadingButton>
                )}
                {canAssignLeadInspector && (
                    <LoadingButton
                        id={`${id}-asssign-inspector`}
                        size="small"
                        onClick={() => togglePersonModal("inspector")}
                        loading={updateAssignee.loading}
                        icon={<StarIcon />}
                        disabled={updateValidator.loading}
                        className={style.buttonSpacer}
                    >
                        Hoofdinspecteur toewijzen
                    </LoadingButton>
                )}
            </Box>
            <Box mt={4}>
                <TopBar
                    id={`${id}-topBar`}
                    reference={requestValues.reference}
                    status={requestValues?.status}
                    category={PROCESS_STATUSES.REQUESTS}
                    leadInspector={
                        hasPermissionToViewAssignee &&
                        requestValues?.status !==
                            REQUESTS.STATUSES.VALIDATION_REQUESTED
                            ? requestValues?.assignee
                            : undefined
                    }
                    validator={
                        hasPermissionToViewAssignee &&
                        requestValues?.status ===
                            REQUESTS.STATUSES.VALIDATION_REQUESTED
                            ? requestValues?.assignee
                            : undefined
                    }
                    linkedInspectionReference={
                        requestValues?.inspectionReference
                    }
                    linkedInspectionId={
                        hasPermissionToVisitLinkedAssignment
                            ? requestValues.inspectionId
                            : undefined
                    }
                    teamName={requestTeam?.description}
                    isRequest
                />
            </Box>

            <Card variant="outlined" className={style.panelSpacer}>
                <CardContent style={{ position: "relative" }}>
                    <InspectionPointInfo
                        id={`${id}-selected-inspectionpoint-info`}
                        inspectionPoint={
                            requestValues?.inspectionPoints &&
                            requestValues.inspectionPoints.find(
                                (el: *) => el.mainInspectionPoint,
                            )
                        }
                        institutionTypes={requestValues?.institutionTypes}
                        hideNetwork
                        mainInstitutionType={requestValues?.mainInstitutionType}
                    />
                </CardContent>
            </Card>

            <div className={style.panelSpacer}>
                <ReasonsPanel
                    id={`${id}-pnlReasons`}
                    reasons={
                        requestValues.reason
                            ? [
                                  {
                                      ...requestValues.reason,
                                      id: requestValues.reason.reasonId,
                                  },
                              ]
                            : []
                    }
                />
            </div>

            <DataRenderer
                id={`${id}-blockDatesAttachments`}
                className={style.panelSpacer}
                afterDate={requestValues.requestedInspectionDateAfter}
                beforeDate={requestValues.requestedInspectionDateBefore}
                creationDate={requestValues.inspectionRequestCreatedOn}
                extraInformation={requestValues.description}
                externalReference={requestValues.externalReferenceId}
                uploaderValue={uploaderValue}
                onUpload={
                    canUploadAttachments && requestValues?.requestId
                        ? handleUpload
                        : undefined
                }
                uploaderLoading={uploaderLoading}
                onAttachmentDelete={
                    !canDeleteAttachments
                        ? undefined
                        : file =>
                              requestValues && requestValues.requestId
                                  ? deleteAttachment(
                                        file,
                                        "deleteAttachmentFromAPI",
                                    )
                                  : deleteAttachment(
                                        file,
                                        "deleteAttachmentFromFE",
                                    )
                }
                onAttachmentClick={file =>
                    requestValues?.requestId && downloadDocument(file)
                }
                attachments={requestValues.attachments}
                hideInnerAttachments={!!requestValues?.requestId}
            />
            <Box mt={4}>
                <SimpleExpansionPanel
                    id={`${id}-locations`}
                    title={`Inspectiepunten (${requestValues.inspectionPoints.length})`}
                    defaultExpanded
                    titleButton={
                        canEditBezoek
                            ? {
                                  icon: <AddIcon />,
                                  text: "Toevoegen",
                                  action: () => toggleVisitModal(true),
                              }
                            : undefined
                    }
                >
                    <Box display="flex" flexWrap="wrap">
                        {requestValues.inspectionPoints.map(
                            (inspectionPoint, index) => (
                                <VisitCard
                                    id={`${id}-lstLocations-${index}`}
                                    key={inspectionPoint.id}
                                    visit={
                                        inspectionPoint.mainInspectionPoint
                                            ? {
                                                  ...inspectionPoint,
                                                  institutionTypes:
                                                      requestValues.institutionTypes,
                                              }
                                            : inspectionPoint
                                    }
                                    index={index}
                                    hideFooter
                                    clickableIcon={canEditBezoek}
                                    onIconClick={handleVisitIconClick}
                                />
                            ),
                        )}
                    </Box>
                </SimpleExpansionPanel>
            </Box>

            <RequestPersonsInfo
                id={`${id}-personalInformation`}
                className={style.panelSpacer}
                feContact={{
                    feContactFirstName: requestValues.feContactFirstName,
                    feContactLastName: requestValues.feContactLastName,
                    feContactEmailAddress: requestValues.feContactEmailAddress,
                    requestedBy: requestValues.requestedBy,
                }}
                submitter={
                    !isComplaint(requestValues.reason)
                        ? undefined
                        : {
                              submitterFirstName:
                                  requestValues.submitterFirstName,
                              submitterLastName:
                                  requestValues.submitterLastName,
                              submitterEmailAddress:
                                  requestValues.submitterEmailAddress,
                              submitterAddress: requestValues.submitterAddress,
                              anonymity: requestValues.anonymity,
                              submitterPhoneNumber:
                                  requestValues.submitterPhoneNumber,
                              dutchSpeaking: requestValues.dutchSpeaking,
                          }
                }
            />

            <Card variant="outlined" className={style.panelSpacer}>
                <CardContent>
                    <Typography type="headline5" className={style.titleSpacer}>
                        Definitieve verslagen
                    </Typography>
                    <Table
                        color="primary"
                        id={`${id}-final-reports`}
                        rows={reportRows}
                        loading={reportsStore.loading}
                        noData="Er zijn nog geen definitieve verslagen"
                        type="reports"
                        definition={finalReportDefinition}
                        rowRenderer={ROWRENDERERCONST.FINAL_REPORTS}
                        onSelect={() => undefined}
                        onDownloadFile={
                            canDownloadReports
                                ? handleDownloadReport
                                : undefined
                        }
                        hasPermissionToPublishReport={false}
                        hasPermissionToSendReport={false}
                        onSendReport={undefined}
                        onDetails={undefined}
                        hidePagination
                        flat
                    ></Table>
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default RequestDetails;
