// @flow

import React, { useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/Info";
import * as yup from "yup";

import ReasonAutoComplete from "../ReasonAutoComplete";
import TextEditor from "../TextEditor";
import Typography from "../Typography";
import { VALIDATION } from "@constants";

const schema = yup.object({
    inspectionReason: yup.string().required(VALIDATION.REQUIRED),
});
/**
 * Props Type
 */
type Props = {
    id: string,
    initialValues: any,
    reasons: Array<*>,
    reasonsInInspection?: Array<*>,
    onSubmit?: (values: *, formikBag: *) => void,
    onReasonSelect?: *,
    isEditMode?: boolean,
    onClose?: () => void,
    disabled?: boolean,
    embedded?: boolean,
    onDescriptionChange?: *,
    pageError?: string,
    single?: boolean,
    loadReasons: () => void,
    loading?: boolean,
};

/**
 * AddReason
 */
const AddReason = ({
    id,
    onSubmit,
    initialValues,
    onReasonSelect,
    reasons,
    reasonsInInspection,
    isEditMode,
    onClose,
    disabled,
    embedded,
    onDescriptionChange,
    pageError,
    single = false,
    loadReasons,
    loading,
}: Props) => {
    const editorRef = useRef<any, any>();

    /**
     * Load reasons
     */
    useEffect(() => {
        loadReasons();
    }, []);

    /**
     * Render
     */
    return (
        <Box display="flex" flexDirection="column">
            <Box
                px={embedded ? 3 : undefined}
                pb={pageError ? 1 : 3}
                display="flex"
                alignItems="center"
            >
                <Typography type="headline6">
                    {single ? "Selecteer reden " : "Selecteer redenen "}
                </Typography>
                {single && (
                    <Tooltip
                        title="Een aanvraag kan maar 1 reden hebben"
                        placement="right"
                        arrow
                    >
                        <InfoIcon fontSize="small" />
                    </Tooltip>
                )}
            </Box>
            {pageError && (
                <Box pb={3}>
                    <Typography type="subtitle2" color="error">
                        {pageError}
                    </Typography>
                </Box>
            )}
            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={schema}
            >
                {({ submitForm, setFieldValue, values, dirty }) => (
                    <Form style={{ maxWidth: "100%" }}>
                        <Box
                            p={embedded ? 3 : undefined}
                            mb={embedded ? undefined : 3}
                        >
                            <ReasonAutoComplete
                                id={`${id}-inspection-reason-selector`}
                                disabled={disabled || loading}
                                options={reasons}
                                reasonId={values.inspectionReason}
                                reasonsInInspection={reasonsInInspection}
                                onSelect={value =>
                                    onReasonSelect
                                        ? onReasonSelect(
                                              "inspectionReason",
                                              value.id,
                                              value.shortDescription,
                                              setFieldValue,
                                          )
                                        : setFieldValue(
                                              "inspectionReason",
                                              value.id,
                                          )
                                }
                            />
                        </Box>
                        <Box
                            p={embedded ? 3 : undefined}
                            mb={embedded ? undefined : 3}
                        >
                            <Box mb={2}>
                                <Typography type="subtitle1">
                                    Beschrijving
                                </Typography>
                            </Box>
                            <TextEditor
                                ref={editorRef}
                                onChange={value => {
                                    onDescriptionChange
                                        ? onDescriptionChange(
                                              "inspectionReasonDescription",
                                              value,
                                              "",
                                              setFieldValue,
                                          )
                                        : setFieldValue(
                                              "inspectionReasonDescription",
                                              value,
                                          );
                                }}
                                content={values.inspectionReasonDescription}
                                id={`${id}-inspectionReasonDescription`}
                                disabled={disabled}
                            />
                        </Box>
                        {onSubmit && (
                            <Box
                                display="flex"
                                mt={embedded ? 2 : undefined}
                                justifyContent="flex-end"
                            >
                                {!!onClose && (
                                    <Box mr={2}>
                                        <Button
                                            id={`${id}-btnCancelEdit`}
                                            color="primary"
                                            onClick={onClose}
                                            disabled={disabled}
                                        >
                                            Annuleren
                                        </Button>
                                    </Box>
                                )}
                                <Button
                                    id={`${id}-btnAddReason`}
                                    variant="contained"
                                    color="primary"
                                    disableElevation={!!embedded}
                                    onClick={() => {
                                        submitForm();
                                        editorRef &&
                                            editorRef.current &&
                                            editorRef.current.reset();
                                    }}
                                    disabled={
                                        !dirty ||
                                        disabled ||
                                        !values.inspectionReason
                                    }
                                    startIcon={
                                        !isEditMode ? <AddIcon /> : undefined
                                    }
                                >
                                    {isEditMode
                                        ? "Wijzigingen opslaan"
                                        : "Reden opslaan"}
                                </Button>
                            </Box>
                        )}
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default AddReason;
