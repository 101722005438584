// @flow
//Styles
import style from "../style.module.scss";
//Libs
import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "gatsby-link";

//Component
import StatusProgress from "../../../StatusProgress";
import AvatarRenderer from "../../../AvatarRenderer";
import Typography from "../../../Typography";
import ZvtZatCell from "../ZvtZatCell";
import AdvancedInspectionPointsCell from "../AdvancedInspectionPointsCell";
//Constants
import { PROCESS_STATUSES, ASSIGNMENTS } from "@constants";
//Utils
import { displayDate, isArrayWithContent, recordHasComplaint } from "@utils";
//Hooks
import { useUserRoleType } from "@hooks";

type Props = {
    id: string,
    record: *,
    index: number,
    renderReasonsToString: *,
};

const AdvancedAssignmentRow = ({
    record,
    index,
    id,
    renderReasonsToString,
}: Props) => {
    const hasComplaint = recordHasComplaint(record);

    const reasons = record.reasons.map(reason => reason.shortDescription);
    const reasonsFromRequests = record.inspectionRequestReasons.map(
        reason => reason.shortDescription,
    );
    const userIsExternal = useUserRoleType("ROLE_EXTERNAL");

    return (
        <TableRow
            id={`${id}-record`}
            key={index}
            tabIndex={-1}
            className={classnames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
            })}
            hover
        >
            <TableCell>
                <div className={style.advancedReferenceCell}>
                    {record.disclosureLevel && (
                        <AvatarRenderer
                            id={`${id}-disclosure`}
                            title={
                                ASSIGNMENTS.DISCLOSURE_LEVELS[
                                    record.disclosureLevel
                                ]
                            }
                            disclosureLevel={record.disclosureLevel}
                            className={style.smallAvatar}
                        />
                    )}
                    {record.reference ? (
                        <Link
                            id={`${id}-${index}-link-inspection-details`}
                            to={`/opdrachten/${record.inspectionId}/inspectie-details`}
                        >
                            <Typography
                                type="subtitle1Regular"
                                color={hasComplaint ? "error" : "dark"}
                            >
                                {record.reference}
                            </Typography>
                        </Link>
                    ) : (
                        "geen opdrachtnummer"
                    )}
                </div>
            </TableCell>

            <TableCell align="left">
                {isArrayWithContent(record.externalReferences) &&
                    record.externalReferences.map((ref, index) => (
                        <Typography
                            key={`${id}-external-reference-${ref}-${index}`}
                            type="body1"
                        >
                            {ref}
                        </Typography>
                    ))}
            </TableCell>

            <AdvancedInspectionPointsCell
                id={id}
                inspectionPoints={record.inspectionPoints}
            />

            <TableCell align="left">
                {record.inspectionPoints?.length > 0 &&
                    record.inspectionPoints[0].externalId}
            </TableCell>

            <ZvtZatCell
                id={`${id}-tableCell-Zvt-Zat`}
                ZVT={record.institutionTypes.map(el => el.name)}
                ZAT={record.activityTypes.map(el => el.name)}
            />

            <TableCell align="left">
                {isArrayWithContent(reasons) && (
                    <Tooltip
                        title={renderReasonsToString(reasons)}
                        placement="bottom"
                    >
                        <Box>
                            <Typography color="disabled" type="caption">
                                Uit opdracht:
                            </Typography>
                            <Box
                                display="flex"
                                className={style.limitedWidthCell}
                            >
                                <Box
                                    mr={0}
                                    className={style.reasonsOverflowEllipsis}
                                >
                                    {renderReasonsToString(reasons, 2)}
                                </Box>
                                <Typography color="disabled" type="body1">
                                    ({record.reasons.length})
                                </Typography>
                            </Box>
                        </Box>
                    </Tooltip>
                )}
                {isArrayWithContent(reasonsFromRequests) && (
                    <Tooltip
                        title={renderReasonsToString(reasonsFromRequests)}
                        placement="bottom"
                    >
                        <Box>
                            <Typography color="disabled" type="caption">
                                Uit aanvragen:
                            </Typography>
                            <Box
                                display="flex"
                                className={style.limitedWidthCell}
                            >
                                <Box
                                    mr={0}
                                    className={style.reasonsOverflowEllipsis}
                                >
                                    {renderReasonsToString(
                                        reasonsFromRequests,
                                        2,
                                    )}
                                </Box>
                                <Typography color="disabled" type="body1">
                                    ({record.inspectionRequestReasons.length})
                                </Typography>
                            </Box>
                        </Box>
                    </Tooltip>
                )}
            </TableCell>

            <TableCell align="left">
                <StatusProgress
                    id={id}
                    category={PROCESS_STATUSES.ASSIGNMENTS}
                    status={record.status}
                />
            </TableCell>
            {!userIsExternal && (
                <TableCell align="left">
                    {isArrayWithContent(record.inspectors) &&
                        record.inspectors.map(person => (
                            <Typography
                                key={`${id}-inspector-${person.inspectorId}`}
                                type="body1"
                            >
                                {person.name}
                            </Typography>
                        ))}
                </TableCell>
            )}
            <TableCell>
                {isArrayWithContent(record.teams) &&
                    record.teams.map(team => team.name).join(", ")}
            </TableCell>

            <TableCell>{displayDate(record.inspectionCreated)}</TableCell>
            <TableCell>{displayDate(record.lastObservation)}</TableCell>
            <TableCell>{displayDate(record.lastVisitDoneOn)}</TableCell>
            <TableCell>{displayDate(record.draftReportSent)}</TableCell>
            <TableCell>{displayDate(record.finalReportSent)}</TableCell>
            <TableCell>{displayDate(record.closedAt)}</TableCell>

            <TableCell>{record.inspectionLabel}</TableCell>
        </TableRow>
    );
};

export default React.memo<Props>(AdvancedAssignmentRow);
