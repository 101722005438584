// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ActionMenu from "../../../ActionMenu";
import classNames from "classnames";

import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import BlockIcon from "@material-ui/icons/Block";

import style from "../style.module.scss";
import Typography from "../../../Typography";
import { displayDate } from "@utils/date";
import { REPORTITEM } from "@constants";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
    canActivateVersions?: boolean,
    canDeactivateVersions?: boolean,
    canCancelVersions?: boolean,
};

const ReportTypesVersionsRow = ({
    record,
    index,
    id,
    callbacks,
    canActivateVersions,
    canDeactivateVersions,
    canCancelVersions,
}: Props) => {
    return (
        <TableRow
            id={`${id}-record-${record.id}`}
            key={`${id}-record-${record.id}`}
            tabIndex={-1}
            className={classNames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
            })}
        >
            <TableCell>
                <Typography
                    type="body1"
                    component="span"
                    style={{ whiteSpace: "nowrap" }}
                >
                    {`V ${record.number}`}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography type="body1" component="span">
                    {record.name ? record.name : ""}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography type="body1" component="span">
                    {REPORTITEM.ACTIVATION_STATUS[record.activationStatus]}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography type="body1" component="span">
                    {displayDate(record.activationDate)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography type="body1" component="span">
                    {displayDate(record.deactivationDate)}
                </Typography>
            </TableCell>
            <TableCell
                align="left"
                style={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                }}
            >
                {record.remark}
            </TableCell>
            <TableCell>
                <ActionMenu
                    id={`${id}-action-menu`}
                    size="small"
                    extraMenuProps={{
                        anchorOrigin: {
                            horizontal: "right",
                            vertical: "bottom",
                        },
                        getContentAnchorEl: null,
                        elevation: 1,
                    }}
                    items={[
                        {
                            text: "Activeer versie",
                            icon: <EventAvailableIcon fontSize="small" />,
                            action: () =>
                                callbacks.onEdit &&
                                callbacks.onEdit("ACTIVATE", record),
                            disabled:
                                !canActivateVersions ||
                                record.activationStatus === "ACTIVE" ||
                                record.activationStatus ===
                                    "ACTIVATION_SCHEDULED" ||
                                record.activationStatus ===
                                    "DEACTIVATION_SCHEDULED",
                        },
                        {
                            text: "Deactiveer versie",
                            icon: <EventBusyIcon fontSize="small" />,
                            action: () =>
                                callbacks.onEdit &&
                                callbacks.onEdit("DEACTIVATE", record),
                            disabled:
                                !canDeactivateVersions ||
                                record.activationStatus === "INACTIVE",
                        },
                        {
                            text: "Annuleer planning",
                            icon: <BlockIcon fontSize="small" />,
                            action: () =>
                                callbacks.onEdit &&
                                callbacks.onEdit("CANCEL", record),
                            disabled:
                                !canCancelVersions ||
                                !record.activationStatus ===
                                    "ACTIVATION_SCHEDULED" ||
                                !record.activationStatus ===
                                    "DEACTIVATION_SCHEDULED" ||
                                record.activationStatus === "INACTIVE" ||
                                record.activationStatus === "ACTIVE",
                        },
                    ]}
                />
            </TableCell>
        </TableRow>
    );
};

export default ReportTypesVersionsRow;
