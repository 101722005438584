//@flow

import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";

import { Modal, Typography, PersonsModal } from "@components/Shared";
import { reopenAssignment } from "@stores/reopen-assignment";
import { PersonUtil } from "@utils";

import style from "./style.module.scss";

type Props = {
    inspectionId: string,
    id?: string,
    isOpen: boolean,
    onClose: () => void,
};
const ReopenModal = ({
    inspectionId,
    id = "ReopenModal",
    isOpen,
    onClose,
}: Props) => {
    const [assignee, setAssignee] = useState(undefined);
    const [showPersonModal, togglePersonModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        isOpen && setAssignee(undefined);
    }, [isOpen]);

    const handleReopen = () =>
        dispatch(reopenAssignment(inspectionId, assignee?.id)).then(
            res => res && onClose(),
        );

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            secondaryButton={{
                text: "Nee",
                action: onClose,
            }}
            primaryButton={{
                text: "Ja, heropenen",
                action: handleReopen,
            }}
            title="Bent je zeker dat u de opdracht wil heropenen?"
        >
            <Box mx={3}>
                <PersonsModal
                    id={`${id}-secondary-modal-persons`}
                    title="Bij heropening toewijzen aan"
                    isOpen={showPersonModal}
                    onClose={() => togglePersonModal(false)}
                    onSelect={(type, person) => setAssignee(person)}
                    onRemove={() => setAssignee(undefined)}
                    selectedPersons={assignee ? [assignee] : assignee}
                    personModalType="assignee"
                    showPills
                    single
                />
                <Typography type="body1" className={style.spacing}>
                    Wil je de opdracht meteen aan iemand toewijzen?
                </Typography>
                {assignee && (
                    <Typography type="body2" className={style.spacing}>
                        De opdracht zal toegewezen worden aan{" "}
                        <Typography type="body2Medium" component="span">
                            {PersonUtil.display(assignee)}
                        </Typography>
                    </Typography>
                )}
                <Button
                    id={`${id}-btn-assign`}
                    variant="contained"
                    onClick={() => togglePersonModal(true)}
                    color="secondary"
                    size="small"
                >
                    {assignee ? "Aanpassen" : "Toewijzen"}
                </Button>
            </Box>
        </Modal>
    );
};

export default ReopenModal;
