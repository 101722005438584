// @flow

import style from "./style.module.scss";

import React, { useEffect, useState, Fragment } from "react";
import * as yup from "yup";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
    submitter as submitterSchema,
    contactFunctionalEntity as contactFunctionalEntitySchema,
    editRequestDateRange as editRequestDateRangeSchema,
} from "../../../definitions";
import { parseDate, isArrayWithContent, displayDate } from "@utils";

import { useUserRoleType } from "@hooks";
//components
import {
    Modal,
    RangePicker,
    TextEditor,
    AddReason,
    Typography,
} from "@components/Shared";
import PersonalInformation from "@components/CreationFlow/PersonalInformation";

type Props = {
    id: string,
    isOpen: boolean,
    toggleModal: () => void,
    request: *,
    loadReasons: () => void,
    reasons: Array<*>,
    reasonsLoading: boolean,
    handleRequestUpdate: (data: *) => void,
    loadFunctionalEntitiesList: () => void,
    getFunctionalEntities: *,
    loadFeUsers: () => void,
    getFeUsers: *,
    user: *,
    teams: Array<*>,
};

const EditDetails = ({
    id,
    isOpen,
    toggleModal,
    request,
    loadReasons,
    reasons,
    reasonsLoading,
    handleRequestUpdate,
    loadFunctionalEntitiesList,
    getFunctionalEntities,
    loadFeUsers,
    getFeUsers,
    user,
    teams,
}: Props) => {
    const initialRangeInfo = {
        beforeDate: parseDate(request?.requestedInspectionDateBefore),
        afterDate: parseDate(request?.requestedInspectionDateAfter),
        creationDate: parseDate(request?.inspectionRequestCreatedOn),
        description: request?.description,
    };
    const initialPersonalInfo = {
        submitter: {
            submitterFirstName: request?.submitterFirstName || "",
            submitterLastName: request?.submitterLastName || "",
            submitterEmailAddress: request?.submitterEmailAddress || "",
            submitterPhoneNumber: request?.submitterPhoneNumber || "",
            submitterAddress: request?.submitterAddress,
            anonymity: request?.anonymity || "",
            dutchSpeaking:
                request?.dutchSpeaking !== null ? request.dutchSpeaking : true,
        },
        contactFunctionalEntity: {
            requestedBy: request?.requestedBy || "",
            functionalEntityTeamId: request?.functionalEntityTeamId || "",
            feContactFirstName: request?.feContactFirstName || "",
            feContactLastName: request?.feContactLastName || "",
            feContactEmailAddress: request?.feContactEmailAddress || "",
            feContactId: request?.feContactId || "",
        },
    };

    const initialReason = {
        inspectionReason: request?.reason?.reasonId,
        inspectionReasonTitle: request?.reason?.shortDescription,
        inspectionReasonDescription: request?.reason?.comment,
    };
    // Range Info
    const [rangeInfo, setRangeInfo] = useState(initialRangeInfo);
    // Personal info
    const [personalInfo, setPersonalInfo] = useState(initialPersonalInfo);
    // Reason
    const [reason, setReason] = useState(initialReason);
    // externalReference
    const [externalReferenceId, setExternalReferenceId] = useState(
        request.externalReferenceId,
    );

    // TeamId
    const [teamId, setTeamId] = useState(request?.teamId);

    const userIsExternal = useUserRoleType("ROLE_EXTERNAL");

    /**
     * Clear all states
     */
    useEffect(() => {
        clear();
    }, [isOpen]);

    const onClose = () => {
        toggleModal();
        clear();
    };

    const clear = () => {
        setRangeInfo(initialRangeInfo);
        setPersonalInfo(initialPersonalInfo);
        setReason(initialReason);
    };

    /**
     * OnSubmit
     */
    const onSubmit = () => {
        const data = {
            ...request,
            attachments: isArrayWithContent(request.attachments)
                ? request.attachments.map(el => el.documentId)
                : [],
            requestedInspectionDateBefore: rangeInfo.beforeDate,
            requestedInspectionDateAfter: rangeInfo.afterDate,
            inspectionRequestCreatedAt: rangeInfo.creationDate,
            description: rangeInfo.description,
            ...personalInfo.submitter,
            submitterAddress: mapSubmitterAddress(
                personalInfo.submitter.submitterAddress,
            ),
            ...personalInfo.contactFunctionalEntity,
            reasonId: reason.inspectionReason,
            reasonDescription: reason.inspectionReasonDescription,
            externalReferenceId,
            teamId,
        };

        yup.object()
            .shape({
                submitter: submitterSchema,
                contactFunctionalEntity: contactFunctionalEntitySchema,
                rangeInfo: editRequestDateRangeSchema,
            })
            .validate(
                {
                    ...personalInfo,
                    rangeInfo: {
                        requestedInspectionDateBefore: rangeInfo.beforeDate,
                        requestedInspectionDateAfter: rangeInfo.afterDate,
                        inspectionRequestCreatedOn: rangeInfo.creationDate,
                    },
                },
                { abortEarly: false },
            )
            .then(() => {
                handleRequestUpdate(data);
                onClose();
                clear();
            })
            .catch(console.log);
    };

    const mapSubmitterAddress = (address: any) => {
        return address
            ? address.city &&
              address.street &&
              address.zipCode &&
              address.streetNumber
                ? address
                : null
            : null;
    };

    /**
     * Update reason
     */
    const updateReason = (
        key: string,
        value: *,
        innerText: *,
        setFieldValue: *,
    ) => {
        if (key === "inspectionReason") {
            setReason({
                ...reason,
                inspectionReason: value,
                inspectionReasonTitle: innerText,
            });
        } else {
            setReason({ ...reason, [key]: value });
        }
        setFieldValue(key, value);
    };

    const handlePersonalInformationChange = (key, data) => {
        setPersonalInfo({
            ...personalInfo,
            [key]: data,
        });
        return Promise.resolve(true);
    };

    /**
     * Render
     */
    return (
        <Modal
            id={`${id}-edit-modal`}
            isOpen={isOpen}
            title={"Aanvraag aanpassen"}
            className={style.wrapper}
        >
            <Box mx={3}>
                {!userIsExternal && (
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <Box mb={3} mr={4}>
                                <Typography type="headline6" color="disabled">
                                    Reden toevoegen
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <AddReason
                                initialValues={reason}
                                id={`${id}-edit-reason`}
                                reasons={reasons}
                                isEditMode
                                disabled={reasonsLoading}
                                loadReasons={loadReasons}
                                onReasonSelect={updateReason}
                                onDescriptionChange={updateReason}
                                single
                            />
                        </Grid>
                    </Grid>
                )}

                <Grid container>
                    <Grid xs={12} md={3} item>
                        <Box my={3} mr={4}>
                            <Typography type="headline6" color="disabled">
                                {userIsExternal
                                    ? "Referentie opdrachtgever"
                                    : "Datums en bijlages aanvullen"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {!userIsExternal && (
                            <Fragment>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    width="100%"
                                >
                                    <Typography type="headline6" mt={6}>
                                        Inspectie uitvoeren tussen:
                                    </Typography>
                                    <Typography type="headline7">
                                        {`Aanmaakdatum: ${displayDate(
                                            rangeInfo.creationDate,
                                        )}`}
                                    </Typography>
                                    <RangePicker
                                        id={`${id}-edit-request-dates`}
                                        onChange={(key, value) =>
                                            setRangeInfo({
                                                ...rangeInfo,
                                                [key]: value,
                                            })
                                        }
                                        {...rangeInfo}
                                        creationDateTitle="Aanvraag aangemaakt op"
                                    />
                                </Box>

                                <Box width="100%" mt={2}>
                                    <Typography type="subtitle1">
                                        Extra nota&apos;s
                                    </Typography>
                                    <TextEditor
                                        onChange={value =>
                                            setRangeInfo({
                                                ...rangeInfo,
                                                description: value,
                                            })
                                        }
                                        content={rangeInfo.description}
                                        id={`${id}-extra-info`}
                                    />
                                </Box>
                            </Fragment>
                        )}
                        <Box width="100%" mt={2}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={externalReferenceId}
                                onChange={event =>
                                    setExternalReferenceId(event.target.value)
                                }
                                label="Referentie opdrachtgever"
                                id={`${id}-input-externalReferenceid`}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <Box mt={4} mr={4}>
                            <Typography type="headline6" color="disabled">
                                Team
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <Box mt={4}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                value={teamId || ""}
                                onChange={event =>
                                    setTeamId(event.target.value)
                                }
                                label="Team"
                                id={`${id}-input-teamid`}
                            >
                                {teams.map(team => (
                                    <MenuItem
                                        id={`option-teamid-${team.id}`}
                                        key={team.id}
                                        value={team.id}
                                    >
                                        {team.description}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} md={3}>
                        <Box mr={4} mt={6}>
                            <Typography type="headline6" color="disabled">
                                Aangevraagd door
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <PersonalInformation
                            id={`${id}-edit-personalinfo`}
                            submitter={personalInfo.submitter}
                            contactFunctionalEntity={
                                personalInfo.contactFunctionalEntity
                            }
                            reason={{
                                reasonId: reason?.inspectionReason,
                                shortDescription: reason?.inspectionReasonTitle,
                            }}
                            loadFunctionalEntitiesList={
                                loadFunctionalEntitiesList
                            }
                            getFunctionalEntities={getFunctionalEntities}
                            onChange={handlePersonalInformationChange}
                            getFeUsers={getFeUsers}
                            loadFeUsers={loadFeUsers}
                            embedded
                            user={user}
                        />
                    </Grid>
                </Grid>

                <Box display="flex" mt={2} justifyContent="flex-end">
                    <Box mr={2}>
                        <Button
                            id={`${id}-cancel-edit-personal-info`}
                            onClick={onClose}
                            color="primary"
                        >
                            Annuleren
                        </Button>
                    </Box>
                    <Button
                        id={`${id}-edit-personalinfo`}
                        color="primary"
                        onClick={onSubmit}
                        variant="contained"
                    >
                        Wijzigingen opslaan
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditDetails;
