// @flow

import style from "./style.module.scss";

import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import PolicyIcon from "@material-ui/icons/Policy";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import SecurityIcon from "@material-ui/icons/Security";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import { Typography } from "@components/Shared";
import { EnvUtil, truncateString } from "@utils";

const isLocalhost = EnvUtil.isLocalhost();
const USER_INSPECTOR =
    process.env.GATSBY_DUMMY_INSPECTOR &&
    JSON.parse(process.env.GATSBY_DUMMY_INSPECTOR);
const USER_SECRETARY =
    process.env.GATSBY_DUMMY_SECRETARY &&
    JSON.parse(process.env.GATSBY_DUMMY_SECRETARY);
const USER_EXTERNAL =
    process.env.GATSBY_DUMMY_EXTERNAL &&
    JSON.parse(process.env.GATSBY_DUMMY_EXTERNAL);
const USER_ADMIN =
    process.env.GATSBY_DUMMY_ADMIN &&
    JSON.parse(process.env.GATSBY_DUMMY_ADMIN);
const USER_MINIADMIN =
    process.env.GATSBY_DUMMY_MINIADMIN &&
    JSON.parse(process.env.GATSBY_DUMMY_MINIADMIN);

const iconForRole = (roles: string[]) => {
    if (roles.includes("ROLE_EXTERNAL")) return <PersonOutlineIcon />;
    if (roles.includes("ROLE_SECRETARIAT")) return <PolicyIcon />;
    if (roles.includes("ROLE_MINIADMIN")) return <SupervisorAccountIcon />;
    if (roles.includes("ROLE_ADMIN")) return <SecurityIcon />;
    return <PersonIcon />;
};

/**
 * user
 */
const User = ({ id, user, isOpen, onLogout, onChangeUser }: *) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = user => {
        onChangeUser && onChangeUser(user);
        setAnchorEl(null);
    };

    return (
        <Box
            id={id}
            className={style.wrapper}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            py={1}
        >
            <Box display="flex" justifyContent="flex-start">
                <Box ml={0} mr={1}>
                    <IconButton
                        aria-controls={`${id}-switch-menu`}
                        aria-haspopup="true"
                        id={`${id}-${
                            isLocalhost ? "btnSwitchUser" : "btnQuickLogout"
                        }`}
                        color="inherit"
                        className={style.avatar}
                        disableRipple
                        onClick={event => setAnchorEl(event.currentTarget)}
                    >
                        {iconForRole(user.roles)}
                    </IconButton>
                    {isLocalhost ? (
                        <Menu
                            id={`${id}-switch-menu`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            <MenuItem
                                onClick={() => handleClose(USER_INSPECTOR)}
                                id={`${id}-menu-option-inspecteur`}
                            >
                                {isOpen ? "Inspecteur" : <PersonIcon />}
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleClose(USER_SECRETARY)}
                                id={`${id}-menu-option-secretariaat`}
                            >
                                {isOpen ? "Secretariaat" : <PolicyIcon />}
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleClose(USER_EXTERNAL)}
                                id={`${id}-menu-option-external`}
                            >
                                {isOpen ? (
                                    "Externe persoon"
                                ) : (
                                    <PersonOutlineIcon />
                                )}
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleClose(USER_MINIADMIN)}
                                id={`${id}-menu-option-miniadmin`}
                            >
                                {isOpen ? (
                                    "Minibeheerder"
                                ) : (
                                    <SupervisorAccountIcon />
                                )}
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleClose(USER_ADMIN)}
                                id={`${id}-menu-option-admin`}
                            >
                                {isOpen ? "Beheerder" : <SecurityIcon />}
                            </MenuItem>
                        </Menu>
                    ) : (
                        <Menu
                            id={`${id}-switch-menu`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            <MenuItem
                                onClick={onLogout}
                                id={`${id}-menu-option-logout`}
                            >
                                Uitloggen <ExitToAppIcon />
                            </MenuItem>
                        </Menu>
                    )}
                </Box>
                <Box width="190px">
                    <h6 className={style.name} id="user_name">
                        {user.given_name} {user.family_name}
                    </h6>
                    <Typography type="body2" color="disabled" id="user_email">
                        {truncateString(user.email, 28)}
                    </Typography>
                </Box>
            </Box>

            {isOpen && (
                <IconButton
                    id={`${id}-btnLogOut`}
                    size="small"
                    aria-label="logout"
                    onClick={onLogout}
                    className={style.logout}
                >
                    <ExitToAppIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default User;
