// @flow
// import libs
import React, {
    useState,
    useEffect,
    useMemo,
    type Node,
    Fragment,
} from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import moment from "moment-timezone";

// reducers
import { set as setAssignmentsValues } from "@stores/assignmentsValues";
import { update as updateAssignment } from "@stores/assignmentsValues";
import { updateLeadInspector } from "@stores/lead-inspector";
import { addReviewer } from "@stores/add-reviewer";
import { saveFeedbackForInspection } from "@stores/save-reviewfeedback";
import { removeInspector } from "@stores/remove-inspectors";
import { addInspector } from "@stores/add-inspectors";
import { addWatcher } from "@stores/add-watcher";
import { deleteWatcher } from "@stores/delete-watcher";
import { load as loadWatchers } from "@stores/get-watchers";
import { updateStatus } from "@stores/update-assignment-status";
import { set as doNotify } from "@stores/notifications";
import { downloadPdf } from "@stores/download-draft-report";
import { loadInspection } from "@stores/inspection";
import { cancelAssignment } from "@stores/cancel-assignment";
import { closeInspection } from "@stores/close-inspection";
import { updateReviewer } from "@stores/update-reviewer";
import { changeDisclosureLevel } from "@stores/update-disclosure-level";
import { getReportsForAssignment } from "@stores/get-reports-for-assignment";
import { updateReactionsAllowed as updateReactionsAllowedAction } from "@stores/reactions-allowed";
import { reopenAssignment } from "@stores/reopen-assignment";
import { postponeAssignment as postponeAssignmentAction } from "@stores/postpone-assignment";
import { declineDraftReport } from "@stores/decline-draft-report";
import { declineFinalReport } from "@stores/decline-final-report";
import { markReportAsReady } from "@stores/mark-report-as-ready";
import { load as loadDeadlineExtensionReasons } from "@stores/deadline-extension-reasons";
import { updateExternalReference } from "@stores/update-external-reference";
import { addNote } from "@stores/add-note";
import { allowDeadlineExtension } from "@stores/allow-deadline-extension";
import { updateLastObservationDate } from "@stores/update-last-observation";
import { assignToMe } from "@stores/assign-to-me";
import { load as getTeams } from "@stores/get-teams";
import { updateInspectionTeam } from "@stores/update-inspection-team";

//components
import {
    AllowReactionsModal,
    AssignmentLayoutFooter,
    DeclineDraftReportModal,
    DisclosureModal,
    ExternalReferenceModal,
    GroupedSelector,
    LastObservationDateModal,
    PostponeModal,
    ReopenModal,
} from "@components/Assignment";
import {
    CancelModal,
    InspectionPointInfo,
    LoadingBox,
    LocationInfoBar,
    PageTitle,
    PersonsModal,
    TeamModal,
    TopBar,
    Typography,
} from "@components/Shared";

// other own
import {
    useMember,
    useMemberNotAssignee,
    usePermission,
    useUserFunction,
    useUserRoleType,
} from "@hooks";
import { ASSIGNMENTS, NOTIFICATIONS, PROCESS_STATUSES } from "@constants";
import type { PersonModalType, Person, Notification, ApiStore } from "@types";
import { isArrayWithContent, PersonUtil, getReviewer, parseDate } from "@utils";

/**
 *   Hoisted methods
 */

const isDetailView = (location: *) =>
    location &&
    !location.pathname.startsWith("/opdrachten/opdrachten-team") &&
    !location.pathname.startsWith("/opdrachten/mijn-opdrachten/") &&
    !location.pathname.startsWith("/opdrachten/openstaand") &&
    !location.pathname.startsWith("/opdrachten/afgewerkt");

const getVisitInformation = (visitId?: string, selectedAssignment?: *) => {
    if (
        !selectedAssignment ||
        !isArrayWithContent(selectedAssignment.visits) ||
        !visitId
    )
        return null;
    const visit = selectedAssignment.visits.find(visit => visit.id === visitId);
    if (!visit) return null;
    return (
        <LocationInfoBar id="visit-location-info" location={visit} isVisit />
    );
};

const checkDeadline = (assignment: *, key: string) =>
    assignment.targetDate &&
    assignment[key] && //$FlowFixMe
    parseDate(assignment.targetDate).isSame(parseDate(assignment[key]));

const getPostponeAssignmentStatuses = (assignment: *) => {
    if (!assignment || !assignment?.status) return false;
    const statusOk = [
        ASSIGNMENTS.STATUSSES.PREPARED,
        ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
        ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
        ASSIGNMENTS.STATUSSES.REACTION_RECEIVED,
        ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
        ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
        ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
        ASSIGNMENTS.STATUSSES.FINAL_REPORT_CREATED,
        ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
    ].includes(assignment.status);

    const isDraft = checkDeadline(assignment, "draftReportDeadline");
    if (isDraft)
        return (
            statusOk && assignment.draftReportDeadlineExtension !== "REQUESTED"
        );

    const isFinal = checkDeadline(assignment, "finalReportDeadline");
    if (isFinal)
        return (
            statusOk && assignment.finalReportDeadlineExtension !== "REQUESTED"
        );

    return statusOk;
};

const getDeadlineState = (assignment: *) => {
    if (!assignment || !assignment.targetDate) return undefined;
    const isDraft = checkDeadline(assignment, "draftReportDeadline");
    if (isDraft && assignment?.draftReportDeadlineExtension !== null)
        return assignment.draftReportDeadlineExtension;

    const isFinal = checkDeadline(assignment, "finalReportDeadline");
    if (isFinal && assignment?.finalReportDeadlineExtension !== null)
        return assignment.finalReportDeadlineExtension;

    return undefined;
};

const getCanChangeAllowReactions = (status?: string) => {
    if (!status) return false;
    return [
        ASSIGNMENTS.STATUSSES.CREATED,
        ASSIGNMENTS.STATUSSES.PLANNED,
        ASSIGNMENTS.STATUSSES.PREPARED,
        ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
        ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
        ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
    ].includes(status);
};

const warningModal = {
    type: NOTIFICATIONS.TYPE.MODAL,
    severity: NOTIFICATIONS.SEVERITY.WARNING,
};
const successModal = {
    type: NOTIFICATIONS.TYPE.MODAL,
    severity: NOTIFICATIONS.SEVERITY.WARNING,
};

/**
 *   Component
 */
type Props = {
    user: *,
    children: Node,
    title: string,
    id: string,
    selectedAssignment: *,
    selectedRecordIndex: number,
    visitId?: string,
    setAssignmentsValues: (*) => void,
    updateAssignment: (*) => void,
    addInspector: (inspectionId: string, inspector: Person) => void,
    removeInspector: (inspectionId: string, inspectorId: string) => Promise<*>,
    addReviewer: (inspectionId: string, reviewer: Person) => Promise<*>,
    addWatcher: (inspectionId: string, watcher: Person) => void,
    loadWatchers: (inspectionId: string) => void,
    deleteWatcher: (inspectionId: string, watcher: Person) => Promise<*>,
    saveFeedbackForInspection: (
        inspectionId: string,
        personId?: string,
    ) => Promise<*>,
    updateLeadInspector: (
        inspectionId: string,
        leadInspector: Person,
        updateAssignee: boolean,
    ) => Promise<*>,
    updateAssignmentStatus: (
        inspectionId: string,
        operation: string,
        path: string,
        newValue: string,
    ) => Promise<any>,
    clearAll: () => void,
    notify: Notification => void,
    loadInspection: (inspectionId: string) => Promise<*>,
    leadInspector: *,
    addReviewerStore: *,
    updateReviewerStore: *,
    addInspectors: *,
    addWatcher: *,
    deleteWatcher: *,
    addWatcherStore: *,
    deleteWatcherStore: *,
    getWatchers: *,
    reactions: Array<*>,
    removeInspectors: *,
    hideHeaderDetails?: boolean,
    hideFooterDetails?: boolean,
    assignmentStatusLoading: boolean,
    saveReviewFeedbackLoading: boolean,
    location: *,
    modules: *,
    inspectionId?: string,
    inspectionStore: ApiStore<*>,
    cancelAssignment: (inspectionId: string, motivation: string) => Promise<*>,
    reopenAssignment: (inspectionId: string) => Promise<*>,
    getReportsForAssignment: (inspectionId: string) => Promise<*>,
    loadDeadlineExtensionReasons: () => void,
    deadlineExtensionReasons: *,
    postponeAssignment: (
        inspectionId: string,
        reason: string,
        comment?: *,
    ) => Promise<*>,
    closeAssignment: (inspectionId: string) => void,
    updateReviewer: (inspectionId: string, reviewer: Person) => Promise<*>,
    changeDisclosureLevel: (inspectionId: string, data: *) => Promise<*>,
    updateDisclosureLevel: *,
    updateReactionsAllowed: (
        inspectionId: string,
        reactionsAllowed: boolean,
    ) => Promise<*>,
    declineDraftReport: (inspectionId: string) => Promise<*>,
    declineDraftReportLoading: boolean,
    declineFinalReport: (inspectionId: string) => Promise<*>,
    declineFinalReportLoading: boolean,
    markReportAsReady: (inspectionId: string, type: string) => Promise<*>,
    markReadyToSendLoading: boolean,
    reportItems: Array<*>,
    loadingPersonInfo: *,
    personInfo: *,
    addNote: (
        inspectionId: string,
        comment: string,
        documentId: ?string,
        internal: boolean,
    ) => Promise<*>,
    addNoteLoading: boolean,
    allowDeadlineExtension: (inspectionId: string) => void,
    updateLastObservationDate: (inspectionId: string, data: *) => Promise<*>,
    updateExternalReference: (
        inspectionId: string,
        value: string,
    ) => Promise<*>,
    assignToMe: (inspectionId: string) => *,
    teams: *,
    getTeams: () => void,
    updateInspectionTeam: (inspectionId: string, data: *) => Promise<*>,
};

const AssignmentLayout = ({
    user,
    hideHeaderDetails,
    hideFooterDetails,
    addInspectors,
    addWatcher,
    getWatchers,
    deleteWatcher,
    addWatcherStore,
    deleteWatcherStore,
    loadWatchers,
    removeInspectors,
    addReviewerStore,
    updateReviewerStore,
    leadInspector,
    loadInspection,
    selectedAssignment,
    inspectionId,
    updateReviewer,
    addInspector,
    addReviewer,
    notify,
    updateLeadInspector,
    updateAssignment,
    removeInspector,
    cancelAssignment,
    closeAssignment,
    updateAssignmentStatus,
    saveFeedbackForInspection,
    // reopenAssignment,
    markReportAsReady,
    declineDraftReport,
    declineFinalReport,
    reportItems,
    children,
    id,
    title,
    assignmentStatusLoading,
    saveReviewFeedbackLoading,
    declineDraftReportLoading,
    declineFinalReportLoading,
    location,
    inspectionStore,
    visitId,
    markReadyToSendLoading,
    postponeAssignment,
    loadDeadlineExtensionReasons,
    deadlineExtensionReasons,
    updateReactionsAllowed,
    changeDisclosureLevel,
    updateDisclosureLevel,
    getReportsForAssignment,
    loadingPersonInfo,
    personInfo,
    addNote,
    addNoteLoading,
    allowDeadlineExtension,
    updateLastObservationDate,
    updateExternalReference,
    assignToMe,
    teams,
    getTeams,
    updateInspectionTeam,
}: Props) => {
    // state hooks
    const [showCancelModal, toggleCancelModal] = useState(false);
    const [showExternalReferenceModal, toggleExternalReferenceModal] = useState(
        false,
    );
    const [showDisclosureModal, toggleDisclosureModal] = useState(false);
    const [showPostponeModal, togglePostponeModal] = useState(false);
    const [showReactionsModal, toggleReactionsModal] = useState(false);
    const [declineReportModal, toggleDeclineReportModal] = useState("");
    const [showReopenModal, toggleReopenModal] = useState(false);
    const [personModal, setPersonModal] = useState({
        show: false,
        title: "",
        type: "",
    });
    const [
        showLastObservationDateModal,
        toggleLastObservationDateModal,
    ] = useState(false);
    const [showTeamModal, toggleTeamModal] = useState(false);

    // authorization hooks
    const userIsMember = useMember();
    const userIsMemberNotAssignee = useMemberNotAssignee();
    const userIsLeadInspector = useUserFunction("leadInspector");
    const userIsAssignee = useUserFunction("assignee");
    const userIsExternal = useUserRoleType("ROLE_EXTERNAL");
    const isInspector = useUserRoleType("ROLE_INSPECTOR");
    const isAdmin = useUserRoleType("ROLE_ADMIN");

    const hasPermissionToViewFooter = usePermission("assignment.footer.read");
    const hasPermissionToResetDisclosureLevel = usePermission(
        "assignment.disclosureLevel.write",
    );
    const hasPermissionToApprovePostponement = usePermission(
        "assignment.postpone.write",
    );

    const hasPermissionToAssignToSelf = usePermission(
        "assignment.assignToSelf.write",
        selectedAssignment?.status,
    );
    const hasPermissionToAllowReactions = usePermission(
        "assignment.allowReactions.write",
        selectedAssignment?.status,
    );
    const hasPermissionToCloseAssignment = usePermission(
        "assignment.close.write",
        selectedAssignment?.status,
    );
    const hasPermissionToCancelAssignment = usePermission(
        "assignment.cancel.write",
    );
    const hasPermissionToEditLeadInspector = usePermission(
        "assignment.leadInspector.write",
    );
    const hasPermissionToEditInspectors = usePermission(
        "assignment.inspectors.write",
    );
    const hasPermissionToEditReviewer = usePermission(
        "assignment.reviewer.write",
    );
    const hasPermissionToReopenAssignment = usePermission(
        "assignment.reopen.write",
        selectedAssignment?.status,
    );

    const canEditExternalReference =
        usePermission(
            "assignment.externalReference.write",
            selectedAssignment?.status,
        ) || userIsMemberNotAssignee;

    const hasPermissionToEditLastObservationDate = usePermission(
        "assignment.lastObservationDate.write",
    );

    useEffect(() => {
        if (
            isDetailView(location) &&
            inspectionId &&
            (!selectedAssignment ||
                selectedAssignment.inspectionId !== inspectionId)
        ) {
            loadInspection(inspectionId);
            loadWatchers(inspectionId);
            getReportsForAssignment(inspectionId);
            getTeams();
        }
    }, [
        location,
        inspectionId,
        selectedAssignment,
        loadInspection,
        loadWatchers,
        getTeams,
    ]);

    useEffect(() => {
        if (!selectedAssignment || !userIsExternal) return;
        const assignmentIsDone = [
            ASSIGNMENTS.STATUSSES.CLOSED,
            ASSIGNMENTS.STATUSSES.CANCELLED,
        ].includes(selectedAssignment.status);
        if (userIsExternal && !assignmentIsDone) navigate("/403");
    }, [selectedAssignment, userIsExternal]);

    /**
     *   variables & conditions
     */

    const selectedPersons = useMemo(() => {
        let { type } = personModal;
        let people = (type !== "" && selectedAssignment[type]) || [];
        if (type === "reviewer") people = getReviewer(selectedAssignment) || [];
        if (type === "watchers") {
            people = getWatchers.data || [];
        }
        return Array.isArray(people) ? people : [people];
    }, [personModal.type, selectedAssignment, getWatchers.data]);

    const disableReportButtons =
        !selectedAssignment?.lastObservationDoneOn ||
        !selectedAssignment?.reportGenerationAvailable ||
        !selectedAssignment?.atLeastOneModuleSubmitted;

    const visitInformation = getVisitInformation(visitId, selectedAssignment);

    const isAssignmentDetail = location.pathname.endsWith("/inspectie-details");

    const modalError =
        leadInspector?.error ||
        addReviewerStore?.error ||
        updateReviewerStore?.error ||
        addInspectors?.error ||
        removeInspectors?.error ||
        addWatcher?.error;

    // can edit last observation date
    const canEditLastObservationDate =
        (hasPermissionToEditLastObservationDate || userIsMemberNotAssignee) &&
        [
            ASSIGNMENTS.STATUSSES.CREATED,
            ASSIGNMENTS.STATUSSES.PLANNED,
            ASSIGNMENTS.STATUSSES.PREPARATION_STARTED,
            ASSIGNMENTS.STATUSSES.PREPARED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
            ASSIGNMENTS.STATUSSES.REOPENED,
        ].includes(selectedAssignment?.status);

    // Can Edit Reviewer
    const canEditReviewer =
        (hasPermissionToEditReviewer || userIsAssignee) &&
        !!selectedAssignment &&
        [
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
            ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
        ].includes(selectedAssignment?.status);

    // Can Add Lead Inspector
    const canAddLeadInspector =
        hasPermissionToEditLeadInspector &&
        selectedAssignment?.status === ASSIGNMENTS.STATUSSES.CREATED;

    // Can Edit Persons
    const canEditInspectors =
        hasPermissionToEditInspectors || userIsLeadInspector;

    // Can Request Postponement
    const canRequestPostponement =
        (hasPermissionToApprovePostponement ||
            userIsMemberNotAssignee ||
            isInspector) &&
        !selectedAssignment?.finalReportDeadlineExtension &&
        !selectedAssignment?.draftReportDeadlineExtension &&
        getPostponeAssignmentStatuses(selectedAssignment);

    // Can edit team
    // Only admin can change team when assignment is closed
    const userIsAdmin = useUserRoleType("ROLE_ADMIN");
    const canEditTeam =
        selectedAssignment?.status !== ASSIGNMENTS.STATUSSES.CLOSED ||
        (userIsAdmin &&
            selectedAssignment?.status === ASSIGNMENTS.STATUSSES.CLOSED);

    // Can Approve Postponement
    const canApprovePostponement =
        hasPermissionToApprovePostponement &&
        (selectedAssignment?.finalReportDeadlineExtension === "REQUESTED" ||
            selectedAssignment?.draftReportDeadlineExtension === "REQUESTED");

    // Can Change Allow Reactions
    const canChangeAllowReactions =
        (hasPermissionToAllowReactions || userIsLeadInspector) &&
        getCanChangeAllowReactions(selectedAssignment?.status);

    // Can Decline Report
    const canDeclineReport =
        selectedAssignment &&
        location.pathname.includes("verslagen") &&
        [
            ASSIGNMENTS.STATUSSES.DRAFT_READY_TO_PUBLISH,
            ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
        ].includes(selectedAssignment.status);

    // Can View Footer
    const canViewFooter = userIsMember || hasPermissionToViewFooter;

    // Can Edit Disclosure Level
    const canEditDisclosureLevel =
        (hasPermissionToResetDisclosureLevel || userIsLeadInspector) &&
        (selectedAssignment?.disclosureLevel !== "NONE" || isAdmin);

    // Can Secretary Assign Himself
    const canSecretaryAssignHimself =
        selectedAssignment &&
        hasPermissionToAssignToSelf &&
        user.sub !== selectedAssignment?.assignee?.id;

    // Can Edit Watchers
    const canEditWatchers = hasPermissionToEditInspectors;

    //Check status assignment for set editing reactions
    const setReactionsIsDefinitive =
        selectedAssignment &&
        [
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
        ].includes(selectedAssignment.status);

    // Assignment is Definitief => assignment status = closed
    const isStatusClosed =
        selectedAssignment &&
        [ASSIGNMENTS.STATUSSES.CLOSED].includes(selectedAssignment.status);
    /**
     *   Functions
     */
    const triggerReviewerModal = () => {
        openPersonModal("Reviewer toewijzen", "reviewer");
    };

    const triggerLeadInspectorModal = () => {
        openPersonModal("Hoofdinspecteur toewijzen", "leadInspector");
    };

    const triggerInspectorsModal = () =>
        openPersonModal("Inspecteurs toewijzen", "inspectors");

    const triggerWatchersModal = () =>
        openPersonModal("Volgers toewijzen", "watchers");

    const openPersonModal = (title: string, type: PersonModalType) =>
        setPersonModal({
            show: true,
            title: title,
            type: type,
        });

    const triggerTeamModal = () => toggleTeamModal(true);

    const closePersonsModal = () => {
        setPersonModal({ show: false, title: "", type: "" });
    };

    const getCallStateForModal = (type: PersonModalType) => {
        switch (type) {
            case "reviewer":
                return addReviewerStore.loading || updateReviewerStore.loading;
            case "leadInspector":
                return leadInspector.loading;
            case "inspectors":
                return addInspectors.loading || removeInspectors.loading;
            case "watchers":
                return addWatcherStore.loading || deleteWatcherStore.loading;
        }
    };

    const refreshData = (callback?: Function) => {
        inspectionId &&
            loadInspection(inspectionId).then(
                action => action && callback && callback(),
            );
    };

    const handleUpdateExternalReference = (reference: *) => {
        updateExternalReference(
            selectedAssignment.inspectionId,
            reference,
        ).then(res => !!res && toggleExternalReferenceModal(false));
    };

    const handleSelectPerson = (type: PersonModalType, person: Person) => {
        if (!inspectionId) return;

        const { status } = selectedAssignment;
        if (type === "reviewer") {
            const doneHandler = response => {
                if (!response) return;
                closePersonsModal();
                notify({
                    ...successModal,
                    message: `${PersonUtil.display(
                        person,
                    )} toegewezen als kwaliteitscontroleur`,
                    primaryAction: () =>
                        navigate("/opdrachten/mijn-opdrachten"),
                    primaryActionText: "Ok",
                });
            };
            // review in progress, reassign to other
            if (status === ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED)
                updateReviewer(inspectionId, person).then(doneHandler);
            // no review in progress, regular assign
            else addReviewer(inspectionId, person).then(doneHandler);
        } else if (type === "leadInspector") {
            doUpdateLeadInspector(person, inspectionId);
        } else if (type === "inspectors") {
            addInspector(inspectionId, person);
        } else if (type === "watchers") {
            addWatcher(inspectionId, person.id);
        }
    };

    /*
     *   execute updating of the lead inspector
     *   + status update if necessary
     */
    const doUpdateLeadInspector = (person: *, inspectionId: string) => {
        let updateAssignee =
            selectedAssignment.assignee?.id ===
            selectedAssignment.leadInspector?.id;

        updateLeadInspector(inspectionId, person, updateAssignee).then(
            response => {
                if (!response) return;
                closePersonsModal();
                if (
                    selectedAssignment.status !== ASSIGNMENTS.STATUSSES.CREATED
                ) {
                    return notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Hoofdinspecteur toegewezen",
                    });
                }

                updateAssignment({
                    status: ASSIGNMENTS.STATUSSES.PLANNED,
                });
                notify({
                    ...successModal,
                    message:
                        "Hoofdinspecteur toegewezen en opdracht status aangepast",
                    primaryAction: () =>
                        navigate(
                            `/opdrachten/${inspectionId}/inspectie-details`,
                        ),
                    primaryActionText: "Verder invullen",
                    secondaryAction: () =>
                        navigate("/opdrachten/opdrachten-team"),
                    secondaryActionText: "Naar overzicht",
                });
            },
        );
    };

    const handleRemovePerson = (type: PersonModalType, person: Person) => {
        if (type === "leadInspector") {
            removeInspector(selectedAssignment.inspectionId, person.id);
        }
        if (type === "inspectors") {
            removeInspector(selectedAssignment.inspectionId, person.id);
        }
        if (type === "reviewer") return confirmDeleteQualityController();
        if (type === "watchers") {
            deleteWatcher(selectedAssignment.inspectionId, person.id);
        }
    };

    const confirmDeleteQualityController = () => {
        notify({
            ...warningModal,
            message: `Bent u zeker dat u deze kwaliteitscontroleur wilt verwijderen?`,
            primaryAction: assignLeadInspectorAsQualityController,
            primaryActionText: "Ok",
            secondaryActionText: "Annuleer",
        });
    };

    const assignLeadInspectorAsQualityController = () => {
        const { leadInspector } = selectedAssignment;
        // does put with empty uuid, followed by post with person's id
        inspectionId &&
            updateReviewer(inspectionId, leadInspector).then(response => {
                if (!response) return;
                refreshData();
                closePersonsModal();
                notify({
                    message: "Kwaliteitscontroleur werd succesvol verwijderd",
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                });
            });
    };

    const handleCancelAssignment = (motivation: string) => {
        cancelAssignment(selectedAssignment.inspectionId, motivation).then(
            response => response && navigate("/opdrachten/opdrachten-team"),
        );
    };

    const onEdit = (type: string) => {
        if (type === "leadInspector") return triggerLeadInspectorModal();
        if (type === "inspectors") return triggerInspectorsModal();
        if (type === "qualityController") return triggerReviewerModal();
        if (type === "watchers") return triggerWatchersModal();
        if (type === "team") return triggerTeamModal();
    };

    const handleAssignmentClose = () =>
        notify({
            ...warningModal,
            message: "Bent u zeker dat u de opdracht wilt afwerken?",
            primaryActionText: "Ja, afwerken",
            primaryAction: () => {
                closeAssignment(selectedAssignment.inspectionId);
            },
            secondaryActionText: "Nee",
        });

    const handleUpdateDisclosure = (value: string) => {
        changeDisclosureLevel(selectedAssignment.inspectionId, { value }).then(
            res => res && toggleDisclosureModal(false),
        );
    };

    const handlePreparationComplete = () => {
        inspectionId &&
            updateAssignmentStatus(
                inspectionId,
                "replace",
                "/status",
                ASSIGNMENTS.STATUSSES.PREPARED,
            ).then(response => {
                if (response) {
                    notify({
                        ...successModal,
                        message: "De voorbereiding werd afgerond",
                        secondaryAction: () =>
                            navigate("/opdrachten/opdrachten-team"),
                        secondaryActionText: "Naar overzicht",
                        primaryAction: refreshData,
                        primaryActionText: "Ok",
                    });
                }
            });
    };

    const sendFeedback = () => {
        saveFeedbackForInspection(selectedAssignment.inspectionId).then(
            response => {
                if (response) {
                    notify({
                        ...successModal,
                        message: "Feedback verzonden",
                        primaryAction: () =>
                            navigate("/opdrachten/mijn-opdrachten"),
                        primaryActionText: "Naar overzicht",
                    });
                }
            },
        );
    };

    const confirmFeedback = () =>
        notify({
            ...warningModal,
            message: "Bent u zeker dat u de feedback wil terugsturen?",
            primaryActionText: "Ja, verzenden",
            primaryAction: sendFeedback,
            secondaryActionText: "Nee",
        });

    const handleSaveReactionsAllowed = (value: boolean) => {
        toggleReactionsModal(false);
        updateReactionsAllowed(selectedAssignment?.inspectionId, value);
    };

    const handleAssignmentReopen = () => toggleReopenModal(true);

    const handleMarkReportAsReady = (type: string) =>
        markReportAsReady(selectedAssignment.inspectionId, type);

    const handleAssignmentPostpone = (reason: string, comment?: string) => {
        const { inspectionId } = selectedAssignment;
        postponeAssignment(inspectionId, reason, comment).then(res => {
            if (res) {
                togglePostponeModal(false);
                loadInspection(inspectionId);
            }
        });
    };

    const handleDeclineReport = (type: string, comment?: string) => {
        const declineAction =
            type === "final" ? declineFinalReport : declineDraftReport;
        if (comment && comment.trim()) {
            Promise.all([
                declineAction(selectedAssignment.inspectionId),
                addNote(selectedAssignment.inspectionId, comment, null, true),
            ]).then(() => {
                toggleDeclineReportModal("");
                refreshData();
            });
        } else
            declineAction(selectedAssignment.inspectionId).then(() => {
                toggleDeclineReportModal("");
                refreshData();
            });
    };

    /*
     * Disable the button if the report is not generated yet
     */
    const disableMarkReportAsReadyToSend = (reportType: string) =>
        !isArrayWithContent(reportItems) ||
        (isArrayWithContent(reportItems) &&
            !reportItems.find(item => item.type === reportType));

    /**
     *  Approve Postponement
     */
    const onPostponementApprove = () =>
        notify({
            ...warningModal,
            message: "Bent u zeker dat u het uitstel wilt goedkeuren?",
            primaryActionText: "Ja",
            primaryAction: () =>
                allowDeadlineExtension(selectedAssignment.inspectionId),
            secondaryActionText: "Nee",
        });

    // NOT lastObservationDate, but the date after which the last observation must be
    // either the visit most in future or the creation date
    const getLastObservationLimit = (assignment: *) => {
        if (!assignment) return null;

        if (!isArrayWithContent(assignment.visits))
            return assignment.inspectionCreatedAt;

        if (assignment.visits.every((visit: *) => !visit.physicalVisit))
            return assignment.inspectionCreatedAt;

        const latestDateReducer = (date, curr) => {
            if (!date) return curr ? moment(curr) : curr;
            return moment(curr).isAfter(date) ? moment(curr) : date;
        };
        const latestVisitDate = assignment.visits.reduce((date, visit) => {
            let datesForVisit = visit.plannedVisitDate
                ? [visit.plannedVisitDate]
                : [];
            if (visit.visitPeriods) {
                const periodEnds = visit.visitPeriods.map(
                    (period: *) => period.endVisitDate,
                );
                datesForVisit = datesForVisit.concat(periodEnds);
            }
            const latestInVisit = datesForVisit.length
                ? datesForVisit.reduce(latestDateReducer)
                : null;
            return latestDateReducer(date, latestInVisit);
        }, null);

        return latestVisitDate;
    };

    const onSecretaryAssignHimself = () =>
        assignToMe(selectedAssignment?.inspectionId);

    const getInspectorNames = (leadInspector, inspectors) => {
        // $FlowFixMe
        const inspectorNames = inspectors?.map(inspector => ({
            name: `${inspector.firstName} ${inspector.lastName}`,
            leadInspector: false,
        }));
        if (leadInspector?.id)
            inspectorNames.push({
                name: `${leadInspector.firstName} ${leadInspector.lastName}`,
                leadInspector: true,
            });
        return inspectorNames;
    };

    const watchAssignment = () => {
        addWatcher(inspectionId, user.sub);
    };
    const unWatchAssignment = () => {
        deleteWatcher(inspectionId, user.sub);
    };
    const getTeamName = (teams, selectedAssignment) =>
        teams.find(obj => obj.id === selectedAssignment.teamId)?.description ||
        "Geen team";

    const handleUpdateTeam = (teamId: string) => {
        updateInspectionTeam(selectedAssignment.inspectionId, {
            uuid: teamId,
        }).then(res => {
            if (res) {
                toggleTeamModal(false);
                refreshData();
            }
        });
    };
    /*
     *   Render loading
     */

    if (
        isDetailView(location) &&
        inspectionStore.loading &&
        (!selectedAssignment ||
            selectedAssignment.inspectionId !== inspectionId)
    )
        return <LoadingBox p={8} />;

    /**
     * Render
     */
    return (
        <Fragment>
            {selectedAssignment && selectedAssignment.reference && (
                <Helmet>
                    <title>{selectedAssignment.reference}</title>
                </Helmet>
            )}
            {!userIsExternal && (
                <Fragment>
                    <PersonsModal
                        id={`${id}-personModal`}
                        isOpen={personModal.show}
                        title={personModal.title}
                        onClose={closePersonsModal}
                        onSelect={handleSelectPerson}
                        onRemove={handleRemovePerson}
                        personModalType={personModal.type}
                        inspectionPointId={
                            selectedAssignment?.inspectionPoint
                                ?.inspectionPointId
                        }
                        selectedPersons={selectedPersons}
                        callInProgress={getCallStateForModal(personModal.type)}
                        showPills={
                            personModal.type === "inspectors" ||
                            personModal.type === "watchers"
                        }
                        error={modalError}
                        single={
                            personModal.type !== "inspectors" &&
                            personModal.type !== "watchers"
                        }
                        onlyInspectors={
                            personModal.type === "inspectors" ||
                            personModal.type === "leadInspector"
                        }
                        excludeCurrentUser={personModal.type === "reviewer"}
                        inspectorNames={getInspectorNames(
                            selectedAssignment?.leadInspector,
                            selectedAssignment?.inspectors,
                        )}
                    />
                    <CancelModal
                        id={`${id}-cancel-modal`}
                        title="Opdracht annuleren"
                        isOpen={showCancelModal}
                        mainBtnTxt="Annuleer opdracht"
                        requests={selectedAssignment?.inspectionRequests}
                        onClose={() => toggleCancelModal(!showCancelModal)}
                        onCancel={handleCancelAssignment}
                    />
                    <DisclosureModal
                        id={`${id}-disclosureModal`}
                        isOpen={showDisclosureModal}
                        onCancel={() => toggleDisclosureModal(false)}
                        value={selectedAssignment?.disclosureLevel}
                        onSave={handleUpdateDisclosure}
                        loading={updateDisclosureLevel.loading}
                        currentDisclosureLevel={
                            selectedAssignment?.disclosureLevel
                        }
                    />
                    <PostponeModal
                        id={id}
                        isOpen={showPostponeModal}
                        onClick={handleAssignmentPostpone}
                        deadlineExtensionReasons={deadlineExtensionReasons}
                        loadDeadlineExtensionReasons={
                            loadDeadlineExtensionReasons
                        }
                        onCancel={() => togglePostponeModal(false)}
                    />

                    <AllowReactionsModal
                        id={`${id}-mdlAllowReactions`}
                        isOpen={showReactionsModal}
                        onCancel={() => toggleReactionsModal(false)}
                        value={selectedAssignment?.allowReactions}
                        onSave={handleSaveReactionsAllowed}
                        setReactionsIsDefinitive={setReactionsIsDefinitive}
                    />

                    <DeclineDraftReportModal
                        id={`${id}-mdlDeclineDraftReport`}
                        isOpen={!!declineReportModal}
                        onCancel={() => toggleDeclineReportModal("")}
                        onSave={(comment: string) =>
                            handleDeclineReport(declineReportModal, comment)
                        }
                        loading={
                            declineDraftReportLoading ||
                            addNoteLoading ||
                            declineFinalReportLoading
                        }
                    />

                    <LastObservationDateModal
                        id={`${id}-mdlLastVisitDoneOn`}
                        value={selectedAssignment?.lastObservationDoneOn}
                        limit={getLastObservationLimit(selectedAssignment)}
                        onClose={() => toggleLastObservationDateModal(false)}
                        isOpen={showLastObservationDateModal}
                        onSubmit={date =>
                            updateLastObservationDate(
                                selectedAssignment?.inspectionId,
                                {
                                    lastObservationDoneOn: date,
                                },
                            ).then(() => toggleLastObservationDateModal(false))
                        }
                    />
                    <ExternalReferenceModal
                        id={`${id}-mdlExternalReference`}
                        isOpen={showExternalReferenceModal}
                        onSave={handleUpdateExternalReference}
                        onCancel={() => toggleExternalReferenceModal(false)}
                        value={selectedAssignment?.externalReference}
                    />
                    <ReopenModal
                        id={`${id}-mdlReopen`}
                        isOpen={showReopenModal}
                        onClose={() => toggleReopenModal(false)}
                        inspectionId={selectedAssignment?.inspectionId}
                    />
                    <TeamModal
                        id={`${id}-mdlTeam`}
                        title="Team aanpassen"
                        isOpen={showTeamModal}
                        onCancel={() => toggleTeamModal(false)}
                        onSave={handleUpdateTeam}
                        options={teams}
                        currentTeam={selectedAssignment?.teamId}
                    />
                </Fragment>
            )}

            <Fragment>
                <PageTitle
                    title={title}
                    secondaryArea={
                        selectedAssignment &&
                        !hideHeaderDetails && (
                            <GroupedSelector
                                id={`${id}-GroupedSelector`}
                                user={user}
                                personsModalClosed={!personModal.show}
                                assignment={selectedAssignment}
                                watchers={getWatchers.data}
                                onLocationClick={id =>
                                    navigate(`/inspectiepunt/${id}/dossier`, {
                                        state: {
                                            source: location?.pathname,
                                        },
                                    })
                                }
                                watchAssignment={watchAssignment}
                                unWatchAssignment={unWatchAssignment}
                                canEditWatchers={canEditWatchers}
                                isInspector={isInspector}
                                canEditInspectors={canEditInspectors}
                                canEditLeadInspector={
                                    hasPermissionToEditLeadInspector
                                }
                                canAddLeadInspector={canAddLeadInspector}
                                canEditReviewer={canEditReviewer}
                                canEditTeam={canEditTeam}
                                onEdit={type => onEdit(type)}
                                onHistoryClick={() =>
                                    navigate(
                                        `/opdrachten/${selectedAssignment?.inspectionId}/historiek`,
                                        {
                                            state: {
                                                source: location?.pathname,
                                            },
                                        },
                                    )
                                }
                                onCancel={
                                    hasPermissionToCancelAssignment
                                        ? () => toggleCancelModal(true)
                                        : undefined
                                }
                                onAssignmentClose={
                                    hasPermissionToCloseAssignment
                                        ? handleAssignmentClose
                                        : undefined
                                }
                                onAssignmentPostpone={
                                    canRequestPostponement
                                        ? () => togglePostponeModal(true)
                                        : undefined
                                }
                                onAssignmentReopen={
                                    hasPermissionToReopenAssignment
                                        ? handleAssignmentReopen
                                        : undefined
                                }
                                onChangeDisclosureLevel={
                                    canEditDisclosureLevel
                                        ? () => toggleDisclosureModal(true)
                                        : undefined
                                }
                                onChangeAllowReactions={
                                    canChangeAllowReactions
                                        ? () => toggleReactionsModal(true)
                                        : undefined
                                }
                                onPostponementApprove={
                                    canApprovePostponement
                                        ? () => onPostponementApprove()
                                        : undefined
                                }
                                onSecretaryAssignHimself={
                                    canSecretaryAssignHimself
                                        ? () => onSecretaryAssignHimself()
                                        : undefined
                                }
                                isStatusClosed={isStatusClosed}
                                teamName={getTeamName(
                                    teams,
                                    selectedAssignment,
                                )}
                            />
                        )
                    }
                />

                <Container maxWidth="xl">
                    {selectedAssignment && (
                        <Box my={4}>
                            <TopBar
                                id={id}
                                reference={selectedAssignment.reference}
                                externalReference={
                                    selectedAssignment.externalReference
                                }
                                onEditExternalReference={
                                    canEditExternalReference
                                        ? () =>
                                              toggleExternalReferenceModal(true)
                                        : undefined
                                }
                                status={selectedAssignment.status}
                                category={PROCESS_STATUSES.ASSIGNMENTS}
                                targetDate={selectedAssignment.targetDate}
                                leadInspector={
                                    selectedAssignment?.leadInspector
                                }
                                allowReactions={
                                    selectedAssignment?.allowReactions
                                }
                                lastObservationDoneOn={
                                    selectedAssignment?.lastObservationDoneOn
                                }
                                onEditLastObservationDate={
                                    canEditLastObservationDate
                                        ? () =>
                                              toggleLastObservationDateModal(
                                                  true,
                                              )
                                        : undefined
                                }
                                deadlineState={getDeadlineState(
                                    selectedAssignment,
                                )}
                            />
                            {!isAssignmentDetail && (
                                <Box mt={2}>
                                    <InspectionPointInfo
                                        isCollapsible={true}
                                        inspectionPoint={
                                            selectedAssignment?.inspectionPoint
                                        }
                                        personInfo={personInfo}
                                        loadingPersonInfo={loadingPersonInfo}
                                        id={`${id}-Inspection-Point-Info`}
                                        disclosureLevel={
                                            selectedAssignment?.disclosureLevel
                                        }
                                        label={selectedAssignment?.label}
                                        inspectionPointId={
                                            selectedAssignment?.inspectionPoint
                                                .inspectionPointId
                                        }
                                        canAssignLabels={false}
                                        hasAttention={
                                            selectedAssignment?.attentionFlag
                                        }
                                        institutionTypes={
                                            selectedAssignment?.institutionTypes
                                        }
                                        canEditInfo={false}
                                        juridicalForm={
                                            selectedAssignment?.inspectionPoint
                                                .juridicalForm
                                        }
                                    />
                                </Box>
                            )}
                            {visitId && (
                                <Box mt={2}>
                                    <Typography type="subtitle1">
                                        {visitInformation}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}

                    {(!isDetailView(location) || !!selectedAssignment) && (
                        <Box py={visitId ? 0 : 2} mt={visitId ? 0 : 5}>
                            {React.Children.map(children, child =>
                                React.cloneElement(child, {
                                    location,
                                }),
                            )}
                        </Box>
                    )}
                    {selectedAssignment && !hideFooterDetails && canViewFooter && (
                        <AssignmentLayoutFooter
                            id={`${id}-footer`}
                            status={selectedAssignment.status}
                            allowReactions={selectedAssignment.allowReactions}
                            location={location}
                            onFeedback={confirmFeedback}
                            onPreparationComplete={handlePreparationComplete}
                            assignmentStatusLoading={assignmentStatusLoading}
                            feedbackLoading={saveReviewFeedbackLoading}
                            disableReportButtons={disableReportButtons}
                            declineReport={{
                                action: toggleDeclineReportModal,
                                loading:
                                    declineDraftReportLoading ||
                                    addNoteLoading ||
                                    declineFinalReportLoading,
                                hidden: !canDeclineReport,
                            }}
                            markReadyToSendButton={{
                                loading: markReadyToSendLoading,
                                disabled: disableMarkReportAsReadyToSend,
                                action: handleMarkReportAsReady,
                            }}
                        />
                    )}
                </Container>
            </Fragment>
        </Fragment>
    );
};

export default connect<*, *, *, *, *, *>(
    ({
        user,
        assignmentsValues,
        leadInspector,
        addReviewer,
        updateReviewer,
        addInspectors,
        removeInspectors,
        addWatcher,
        deleteWatcher,
        getWatchers,
        updateAssignmentStatus,
        downloadDraftReport,
        saveReviewFeedback,
        reactions,
        modules,
        inspection,
        declineDraftReport,
        declineFinalReport,
        markReportAsReady,
        reportItems,
        deadlineExtensionReasons,
        updateDisclosureLevel,
        getPersonInfo,
        addNote,
        getTeams,
        updateInspectionTeam,
    }) => ({
        id: "AssignmentLayout",
        user,
        selectedAssignment: assignmentsValues.selectedRecord,
        selectedRecordIndex: assignmentsValues.selectedRecordIndex,
        leadInspector,
        addReviewerStore: addReviewer,
        updateReviewerStore: updateReviewer,
        addInspectors,
        removeInspectors,
        addWatcherStore: addWatcher,
        deleteWatcherStore: deleteWatcher,
        getWatchers,
        loadWatchers,
        draftReport: { ...downloadDraftReport },
        assignmentStatusLoading: updateAssignmentStatus.loading,
        saveReviewFeedbackLoading: saveReviewFeedback.loading,
        reactions,
        modules,
        inspectionStore: inspection,
        declineDraftReportLoading: declineDraftReport.loading,
        declineFinalReportLoading: declineFinalReport.loading,
        markReadyToSendLoading: markReportAsReady.loading,
        reportItems,
        deadlineExtensionReasons,
        updateDisclosureLevel,
        loadingPersonInfo: getPersonInfo.loading,
        personInfo: getPersonInfo.data,
        addNoteLoading: addNote.loading,
        teams: getTeams.data,
        updateInspectionTeam,
    }),
    (dispatch: *) => ({
        downloadPdf: (url: string) => dispatch(downloadPdf(url)),

        setAssignmentsValues: (reset: *) =>
            dispatch(setAssignmentsValues(reset)),
        updateAssignment: (selectedAssignment: *) =>
            dispatch(updateAssignment({ selectedRecord: selectedAssignment })),
        updateLeadInspector: (inspectionId, inspector, updateAssignee) =>
            dispatch(
                updateLeadInspector(inspectionId, inspector, updateAssignee),
            ),
        addInspector: (inspectionId, inspector) =>
            dispatch(addInspector(inspectionId, inspector)),
        removeInspector: (inspectionId, inspectorId) =>
            dispatch(removeInspector(inspectionId, inspectorId)),
        addWatcher: (inspectionId, watcherId) =>
            dispatch(addWatcher(inspectionId, watcherId)),
        loadWatchers: (inspectionId: string) =>
            dispatch(loadWatchers({ path: { inspectionId } })),
        deleteWatcher: (inspectionId, watcherId) =>
            dispatch(deleteWatcher(inspectionId, watcherId)),
        addReviewer: (inspectionId, reviewer) =>
            dispatch(addReviewer(inspectionId, reviewer)),
        saveFeedbackForInspection: (inspectionId: string, personId: string) =>
            dispatch(saveFeedbackForInspection(inspectionId, personId)),
        updateAssignmentStatus: (inspectionId, operation, path, newValue) =>
            dispatch(updateStatus(inspectionId, operation, path, newValue)),
        updateReviewer: (inspectionId, person) =>
            dispatch(updateReviewer(inspectionId, person)),

        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
        loadInspection: inspectionId => dispatch(loadInspection(inspectionId)),
        cancelAssignment: (inspectionId, motivation) =>
            dispatch(cancelAssignment(inspectionId, motivation)),
        reopenAssignment: (inspectionId: string) =>
            dispatch(reopenAssignment(inspectionId)),
        closeAssignment: inspectionId =>
            dispatch(closeInspection(inspectionId)),
        postponeAssignment: (inspectionId, reason, comment) =>
            dispatch(postponeAssignmentAction(inspectionId, reason, comment)),

        changeDisclosureLevel: (inspectionId, data) =>
            dispatch(changeDisclosureLevel(inspectionId, data)),
        updateReactionsAllowed: (inspectionId, reactionsAllowed) =>
            dispatch(
                updateReactionsAllowedAction(inspectionId, reactionsAllowed),
            ),
        declineDraftReport: (inspectionId: string) =>
            dispatch(declineDraftReport(inspectionId)),
        declineFinalReport: (inspectionId: string) =>
            dispatch(declineFinalReport(inspectionId)),
        markReportAsReady: (inspectionId, type) =>
            dispatch(markReportAsReady(inspectionId, type)),
        loadDeadlineExtensionReasons: () =>
            dispatch(loadDeadlineExtensionReasons({}, true)),
        getReportsForAssignment: (inspectionId: string) =>
            dispatch(getReportsForAssignment(inspectionId)),
        addNote: (
            inspectionId: string,
            comment: string,
            documentId: ?string,
            internal: boolean,
        ) => dispatch(addNote(inspectionId, comment, documentId, internal)),
        allowDeadlineExtension: (inspectionId: *) =>
            dispatch(allowDeadlineExtension(inspectionId)),
        updateLastObservationDate: (inspectionId: string, data: *) =>
            dispatch(updateLastObservationDate(inspectionId, data)),
        updateExternalReference: (inspectionId: string, value: string) =>
            dispatch(updateExternalReference(inspectionId, value)),

        assignToMe: (inspectionId: string) =>
            dispatch(assignToMe(inspectionId)),
        getTeams: () => dispatch(getTeams()),
        updateInspectionTeam: (inspectionId: string, data: *) =>
            dispatch(updateInspectionTeam(inspectionId, data)),
    }),
)(AssignmentLayout);
