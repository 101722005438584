// @flow

//styles
import style from "../style.module.scss";

//libs
import React, { useState, Fragment } from "react";
import classnames from "classnames";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";

//icons
import MyLocationIcon from "@material-ui/icons/MyLocation";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import Typography from "@components/Shared/Typography";
import LinkRenderer from "../LinkRenderer";
import { ASSIGNMENTS } from "@constants";
import { storage, isArrayWithContent } from "@utils";

type Props = {
    id: string,
    isFolded: boolean,
    reference: string,
    subItems: Array<*>,
    inspectionId: string,
    isActive: *,
    userIsMemberOfAssignment: boolean,
    status: string,
    isActiveVisit: *,
    assignment: *,
    noFormStatuses: Array<*>,
};

const SubListRenderer = ({
    id,
    isFolded,
    reference,
    subItems,
    inspectionId,
    isActive,
    userIsMemberOfAssignment,
    status,
    isActiveVisit,
    noFormStatuses,
    assignment,
}: Props) => {
    const [showVisits, toggleShowVisit] = useState(
        false || storage.get("showVisits") === "true",
    );

    const [
        showLocationsWithoutForms,
        toggleShowLocationsWithoutForms,
    ] = useState(false || storage.get("showLocationsWithoutForms") === "true");

    /*
     *   flip switch for locations without forms
     */
    const toggleLocationsWithoutForms = (ev: any) => {
        toggleShowLocationsWithoutForms(ev.target.checked);
        storage.set("showLocationsWithoutForms", ev.target.checked.toString());
    };

    /*
     *   show/hide visits
     */
    const toggleShowVisits = (showVisits: boolean) => {
        toggleShowVisit(showVisits);
        storage.set("showVisits", showVisits);
    };

    const canToggleVisits = !assignment?.visits
        ? false
        : [ASSIGNMENTS.STATUSSES.CREATED, ASSIGNMENTS.STATUSSES.PLANNED].every(
              status => status !== assignment.status,
          );
    /*
     *   get right list of visits for state
     */
    const visitsToShow = !assignment?.visits
        ? undefined
        : !canToggleVisits || showLocationsWithoutForms
        ? assignment.visits
        : assignment.visits.filter(visit => !!visit.moduleCount);

    const shouldShowForms = (status?: string) => {
        if (noFormStatuses.length)
            return !!status && !noFormStatuses.includes(status);
        else return true;
    };

    return (
        <List>
            <Box my={4} px={3} display="flex">
                {isFolded && (
                    <Typography
                        type="subtitle1"
                        color="primary"
                        id={`${id}-sub-list-reference`}
                    >
                        {reference}
                    </Typography>
                )}
            </Box>

            {subItems.map(item => (
                <Fragment key={item.title}>
                    {item.id !== "formulieren" && (
                        <LinkRenderer
                            id={`${id}-assignment-page-link-${item.to}`}
                            title={item.title}
                            icon={item.icon}
                            to={`/opdrachten/${inspectionId}/${item.to}`}
                            className={classnames(style.link, {
                                [style.active]: isActive(item.to),
                            })}
                            isFolded={isFolded}
                            hidden={!!item.hidden}
                        />
                    )}
                    {item.id === "formulieren" &&
                        !item.hidden &&
                        userIsMemberOfAssignment &&
                        shouldShowForms(status) && (
                            <Fragment>
                                <ListItem
                                    button
                                    id={`${id}-visits-collapse-button`}
                                    onClick={() =>
                                        toggleShowVisits(!showVisits)
                                    }
                                >
                                    <Tooltip
                                        placement="right"
                                        title={isFolded ? "" : item.title}
                                    >
                                        <ListItemIcon
                                            className={style.menuIcon}
                                        >
                                            {item.icon}
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography type="subtitle2">
                                                {item.title}
                                            </Typography>
                                        }
                                    />
                                    {canToggleVisits && (
                                        <Box>
                                            <Tooltip
                                                title={
                                                    showLocationsWithoutForms
                                                        ? "Inspectiepunten zonder formulieren verbergen"
                                                        : "Inspectiepunten zonder formulieren weergeven"
                                                }
                                            >
                                                <Switch
                                                    size="small"
                                                    checked={
                                                        showLocationsWithoutForms
                                                    }
                                                    onChange={
                                                        toggleLocationsWithoutForms
                                                    }
                                                    onClick={ev =>
                                                        ev.stopPropagation()
                                                    }
                                                    color="secondary"
                                                    inputProps={{
                                                        "aria-label": showLocationsWithoutForms
                                                            ? "Inspectiepunten zonder formulieren verbergen"
                                                            : "Inspectiepunten zonder formulieren weergeven",
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    )}
                                    {showVisits ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItem>
                                {isArrayWithContent(visitsToShow) && (
                                    <Collapse in={showVisits} timeout="auto">
                                        <List>
                                            {visitsToShow &&
                                                visitsToShow.map(visit => (
                                                    <LinkRenderer
                                                        key={visit.id}
                                                        id={`${id}-visit-${visit.id}`}
                                                        title={
                                                            visit.title ||
                                                            visit.inspectionPointName
                                                        }
                                                        icon={
                                                            <MyLocationIcon fontSize="small" />
                                                        }
                                                        to={`/opdrachten/${inspectionId}/inspectiepunten/${visit.id}/${item.to}`}
                                                        isFolded={isFolded}
                                                        className={classnames(
                                                            style.link,
                                                            {
                                                                [style.active]: isActiveVisit(
                                                                    visit.id,
                                                                ),
                                                            },
                                                        )}
                                                    />
                                                ))}
                                        </List>
                                    </Collapse>
                                )}
                            </Fragment>
                        )}
                </Fragment>
            ))}
        </List>
    );
};

export default SubListRenderer;
