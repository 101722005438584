// @flow

import style from "./style.module.scss";

import React, { useState, useEffect, Fragment, type Node } from "react";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";

// subcomponents
import TypeGroups from "./Filters/TypeGroups";
import InspectionPointFilter from "./Filters/InspectionPointFilter";
import DateFilter from "./Filters/DateFilter";
import ReasonFilter from "./Filters/ReasonFilter";
import ZVGFilter from "./Filters/ZVGFilter";
import AttentionFilter from "./Filters/AttentionFilter";
import InspectionPointIdFilter from "./Filters/InspectionPointIdFilter";
import SingleDate from "./Filters/SingleDate";
import DateRangeFilter from "./Filters/DateRangeFilter";
import DateFilterEditMode from "./Filters/DateFilterEditMode";
import PostponedFilter from "./Filters/PostponedFilter";

// own other
import {
    LabelFilter,
    ZVTFilter,
    ZATFilter,
    FeUserFilter,
} from "../ApiCheckboxListWithSearch";
import { ReportTypeFilter, TeamFilter } from "../ApiCheckboxList";
import CheckboxList from "../CheckboxList";
import PersonSelector from "../PersonSelector";
import PersonChip from "../PersonChip";
import { isArrayWithContent, isDefined } from "@utils";
import {
    DateFilter as DateFilterSchema,
    DateFilterEditMode as DateFilterEditModeSchema,
} from "../../../definitions/schemas";
import { REQUESTS, ASSIGNMENTS } from "@constants";

/*
 *   methods that don't depend on state/props directly
 *   shouldn't be recreated on every render
 */
const getBadgeContent = tempValue => {
    if (Array.isArray(tempValue)) return tempValue.length || undefined;
    else if (typeof tempValue === "object") {
        const keys = Object.keys(tempValue);
        if (!keys.length) return undefined;
        return keys
            .filter(key => tempValue[key] && key !== "dateFilter")
            .reduce(
                (sum, curr) =>
                    Array.isArray(tempValue[curr])
                        ? (sum += tempValue[curr].length)
                        : (sum += 1),
                0,
            );
    } else return tempValue && tempValue !== "" ? 1 : undefined;
};

// type Option = { label: string, value: *, index?: number };
type Props = {
    id: string,
    filterId: string,
    label: string | Node,
    onFilter: (*) => void,
    headerType: string,
    value?: *,
    icon?: *,
    options?: any[] | { [string]: any },
    singleSelect?: boolean,
    editMode?: boolean,
};

/**
 * TableFilterButton
 */
const TableFilterButton = ({
    id,
    label,
    filterId,
    onFilter,
    value,
    icon,
    options,
    headerType,
    singleSelect = false,
    editMode = false,
}: Props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [tempValue, setTempValue] = useState<any>(value ? value : undefined);
    const [disableSave, setDisableSave] = useState(false);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setTempValue(value ? value : undefined);
    }, [value]);

    useEffect(() => {
        //$FlowFixMe
        if (headerType === "text" && !isArrayWithContent(options)) {
            setDisableSave(tempValue ? tempValue.length < 3 : false);
        }
        if (headerType === "date") {
            if (!tempValue) setDisableSave(false);
            else if (editMode)
                setDisableSave(
                    !DateFilterEditModeSchema.isValidSync(tempValue),
                );
            else setDisableSave(!DateFilterSchema.isValidSync(tempValue));
        }
    }, [tempValue]);

    const cancel = () => {
        setAnchorEl(null);
        setTempValue(value ? value : undefined);
    };

    const saveAndClose = newValue => {
        const value =
            Array.isArray(newValue) && newValue.length === 0
                ? undefined
                : newValue;
        setAnchorEl(null);
        newValue !== tempValue && setTempValue(newValue);
        onFilter({ key: filterId, value });
    };

    const selectValue = value => {
        if (isSelected(value) && Array.isArray(tempValue))
            setTempValue(tempValue.filter(el => el !== value));
        else if (Array.isArray(tempValue)) setTempValue([...tempValue, value]);
        else setTempValue([value]);
    };

    const isSelected = value =>
        isArrayWithContent(tempValue) && tempValue.includes(value);

    const handleEnter = ev => {
        if (ev.which === 13 || ev.keyCode === 13 || ev.key === "Enter")
            !disableSave && saveAndClose(tempValue);
    };

    const setAnchor = ev => {
        if (ev.target.tagName === "BUTTON") return setAnchorEl(ev.target);
        let anchor = ev.target;
        while (anchor.tagName !== "BUTTON") {
            anchor = anchor.parentElement;
        }
        setAnchorEl(anchor);
    };

    const selectPerson = (singleSelect?: boolean) => (person: *) => {
        if (!tempValue || singleSelect) setTempValue([person]);
        else if (Array.isArray(tempValue)) setTempValue([...tempValue, person]);
    };

    const removePerson = (person: *) => {
        if (!Array.isArray(tempValue)) return;
        const filtered = tempValue.filter(el => el.id !== person.id);
        if (filtered.length > 0) setTempValue(filtered);
        else setTempValue(undefined);
    };

    const handleGroupSelect = (groupType: string, value: string) => {
        if (tempValue && isArrayWithContent(tempValue[groupType])) {
            const isSelectedInGroup = tempValue[groupType].includes(value);
            let groupUpdate = isSelectedInGroup
                ? tempValue[groupType].filter(el => el !== value)
                : tempValue[groupType].concat(value);
            groupUpdate = isArrayWithContent(groupUpdate) ? groupUpdate : null;
            const update = { ...tempValue, [groupType]: groupUpdate };
            if (Object.keys(update).every(key => !update[key]))
                setTempValue(undefined);
            else setTempValue(update);
        } else if (tempValue) {
            setTempValue({ ...tempValue, [groupType]: [value] });
        } else setTempValue({ [groupType]: [value] });
    };

    return (
        <Fragment>
            <Badge
                id={`${id}-badge`}
                classes={{ badge: style.badge }}
                badgeContent={getBadgeContent(tempValue)}
            >
                <Button
                    id={id}
                    color="primary"
                    onClick={setAnchor}
                    size={editMode ? "large" : "small"}
                    startIcon={icon}
                    variant={anchorEl ? "contained" : undefined}
                    classes={{ root: style.mainButton }}
                >
                    {label}
                </Button>
            </Badge>
            <Popover
                id={`${id}-fade-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={() => cancel()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                TransitionProps={{
                    mountOnEnter:
                        !Array.isArray(options) &&
                        isDefined(options?.mounOnEnter) //$FlowFixMe
                            ? options.mountOnEnter
                            : true,
                }}
            >
                <Box
                    p={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    {Array.isArray(options) && (
                        <CheckboxList
                            id={id}
                            className={style.list}
                            selection={tempValue}
                            list={options}
                            onSelect={selectValue}
                            singleSelect={singleSelect}
                            menu
                            disablePadding
                        />
                    )}
                    {!Array.isArray(options) && headerType === "text" && (
                        <TextField
                            autoFocus
                            fullWidth
                            id={`${id}-input-textfield`}
                            label={label}
                            name={label}
                            variant="outlined"
                            value={tempValue || ""}
                            onChange={ev => setTempValue(ev.target.value)}
                            error={!!tempValue && tempValue.length < 3}
                            helperText={
                                tempValue && tempValue.length < 3
                                    ? "Minimum 3 karakters"
                                    : undefined
                            }
                            size="small"
                            InputProps={{ onKeyDown: handleEnter }}
                        />
                    )}
                    {headerType === "person" && !Array.isArray(options) && (
                        <Box maxWidth="25em">
                            {isArrayWithContent(tempValue) && (
                                <Box className={style.chipBox}>
                                    {tempValue.map(person => (
                                        <PersonChip
                                            key={person.id}
                                            person={person}
                                            onDelete={removePerson}
                                            className={style.chip}
                                            id={`${id}-person-${person.id}`}
                                        />
                                    ))}
                                </Box>
                            )}
                            <PersonSelector
                                id={`${id}-person-selector`}
                                onSelect={selectPerson(options?.single)}
                                onRemove={removePerson}
                                callInProgress={false}
                                selectedPersons={tempValue}
                                single={options?.single}
                                hidePersonsForIp={options?.hidePersonsForIp}
                            />
                        </Box>
                    )}
                    {headerType === "groups" && (
                        <TypeGroups
                            id={id}
                            onSelect={handleGroupSelect}
                            zvtValue={
                                tempValue && tempValue["institution-type-id"]
                            }
                            zatValue={
                                tempValue && tempValue["activity-type-id"]
                            }
                        />
                    )}
                    {headerType === "inspectionPoint" &&
                        !Array.isArray(options) && (
                            <InspectionPointFilter
                                id={id}
                                value={tempValue}
                                onChange={setTempValue}
                                onValidationChange={valid =>
                                    setDisableSave(!valid)
                                }
                                onEnterPress={handleEnter}
                                options={options}
                            />
                        )}
                    {headerType === "inspectionPointId" &&
                        !Array.isArray(options) && (
                            <InspectionPointIdFilter
                                id={id}
                                value={tempValue}
                                onSelect={selectValue}
                                searchLabel={options?.searchLabel}
                                queryOptions={options?.queryOptions}
                            />
                        )}
                    {headerType === "date" &&
                        !editMode &&
                        !Array.isArray(options) && (
                            <DateFilter
                                id={id}
                                onChange={setTempValue}
                                value={tempValue}
                                showVisitFields={options?.showVisitFields}
                            />
                        )}
                    {headerType === "date" &&
                        editMode &&
                        !Array.isArray(options) && (
                            <DateFilterEditMode
                                id={id}
                                onChange={setTempValue}
                                value={tempValue}
                            />
                        )}
                    {headerType === "date-range" && !Array.isArray(options) && (
                        <DateRangeFilter
                            id={id}
                            onChange={setTempValue}
                            value={tempValue}
                        />
                    )}
                    {headerType === "singleDate" && !Array.isArray(options) && (
                        <SingleDate
                            id={id}
                            onChange={setTempValue}
                            value={tempValue}
                        />
                    )}
                    {headerType === "reason" && (
                        <ReasonFilter
                            id={id}
                            value={tempValue}
                            onSelect={selectValue}
                        />
                    )}
                    {headerType === "reportType" && (
                        <ReportTypeFilter
                            id={id}
                            value={tempValue}
                            onSelect={selectValue}
                            singleSelect
                            menu
                        />
                    )}
                    {headerType === "attentionFlag" && (
                        <AttentionFilter
                            id={id}
                            value={tempValue}
                            onChange={setTempValue}
                        />
                    )}
                    {headerType === "team" && (
                        <TeamFilter
                            id={id}
                            value={tempValue}
                            onSelect={selectValue}
                            menu
                        />
                    )}
                    {headerType === "label" && (
                        <LabelFilter
                            id={id}
                            value={tempValue}
                            onSelect={selectValue}
                            searchLabel="Label zoeken"
                            menu
                        />
                    )}
                    {headerType === "ZVG" && (
                        <ZVGFilter
                            id={id}
                            value={tempValue}
                            onSelect={setTempValue}
                        />
                    )}
                    {headerType === "ZVT" && (
                        <ZVTFilter
                            id={id}
                            value={tempValue}
                            onSelect={selectValue}
                            searchLabel="Zorgvoorzieningstype zoeken"
                            menu
                        />
                    )}
                    {headerType === "ZAT" && (
                        <ZATFilter
                            id={id}
                            value={tempValue}
                            onSelect={selectValue}
                            searchLabel="Zorgactiviteitstype zoeken"
                            menu
                        />
                    )}
                    {headerType === "feUser" && (
                        <FeUserFilter
                            id={id}
                            value={tempValue}
                            onSelect={selectValue}
                            searchLabel="Aanvrager zoeken"
                            menu
                        />
                    )}
                    {headerType === "requester" && (
                        <CheckboxList
                            id={id}
                            list={REQUESTS.REQUESTERS.map((el, index) => ({
                                ...el,
                                index,
                            }))}
                            onSelect={selectValue}
                            selection={tempValue}
                            className={style.list}
                            disablePadding
                            menu
                        />
                    )}
                    {headerType === "disclosure" && (
                        <CheckboxList
                            id={id}
                            list={Object.keys(
                                ASSIGNMENTS.DISCLOSURE_LEVELS,
                            ).map((key, index) => ({
                                value: key,
                                label: ASSIGNMENTS.DISCLOSURE_LEVELS[key],
                                index,
                            }))}
                            onSelect={selectValue}
                            selection={tempValue}
                            className={style.list}
                            disablePadding
                            menu
                        />
                    )}
                    {headerType === "postponed" && (
                        <PostponedFilter
                            id={id}
                            onChange={setTempValue}
                            value={tempValue}
                        />
                    )}
                    <Button
                        id={`${id}-btnClear`}
                        size="small"
                        color="primary"
                        aria-label="clear filter value"
                        onClick={() => saveAndClose(undefined)}
                        className={style.button}
                        fullWidth
                    >
                        Wissen
                    </Button>
                    <Button
                        id={`${id}-btnSave`}
                        size="small"
                        color="primary"
                        variant="contained"
                        disabled={disableSave}
                        aria-label="save filter value"
                        onClick={() => saveAndClose(tempValue)}
                        className={style.button}
                        fullWidth
                        disableElevation
                    >
                        Opslaan
                    </Button>
                </Box>
            </Popover>
        </Fragment>
    );
};

export default TableFilterButton;
