// @flow
import style from "./style.module.scss";

import React, { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";

import Box from "@material-ui/core/Box";

import ReasonsList from "../ReasonsList";
import { Typography, Modal, Redirect, AddReason } from "@components/Shared";

import { REDIRECTIONS, NOTIFICATIONS } from "@constants";
import type { Notification } from "@types";

/**
 * Props Type
 */
type Props = {
    id: string,
    onSubmit: (updatedProgressState: *, extraKeys?: string[]) => void,
    loadReasons: () => void,
    notify: (notification: Notification) => Promise<any>,
    reasons: *,
    loading: boolean,
    flow: string,
    progressStore: *,
};

/**
 * Define Reasons
 */
const DefineReason = ({
    id,
    onSubmit,
    loadReasons,
    notify,
    reasons,
    loading,
    flow,
    progressStore,
}: Props) => {
    const [reasonToEdit, setReasonToEdit] = useState(undefined);
    const [currentReason, setCurrentReason] = useState({
        value: "",
        label: "",
    });

    /**
     * Select Reason
     */
    const onReasonSelect = (
        name: string,
        value?: ?string,
        label: string,
        setFieldValue: Function,
    ) => {
        if (!value) return; // when selecting subtitle, value is undefined
        setCurrentReason({ value, label });
        setFieldValue(name, value);
    };

    /**
     * Submit handler
     */
    const handleSubmit = (values: *, { resetForm }: Function) => {
        const reasonsToSubmit = progressStore.selectedReasons || [];

        //Edit reason
        if (reasonToEdit) {
            const index = reasonsToSubmit.findIndex(
                element => element.hash === reasonToEdit.hash,
            );
            if (index === -1) return;
            reasonsToSubmit[index] = {
                hash: `${values.inspectionReason}_${Date.now()}`,
                shortDescription: currentReason.label,
                id: values.inspectionReason,
                comment: values.inspectionReasonDescription,
            };
        } else {
            reasonsToSubmit.push({
                shortDescription: currentReason.label,
                id: values.inspectionReason,
                comment: values.inspectionReasonDescription,
                hash: `${values.inspectionReason}_${Date.now()}`,
            });
        }

        onSubmit(reasonsToSubmit);

        // must do this before closing the modal
        // otherwise acting on an unmounted component
        resetForm();
        setCurrentReason({
            value: "",
            label: "",
        });
        setReasonToEdit(undefined);
    };

    /**
     * Edit reason
     */
    const onEditReason = (reason: *) => {
        setReasonToEdit(
            progressStore.selectedReasons.find(el => el.hash === reason.hash),
        );
        setCurrentReason({ value: reason.id, label: reason.shortDescription });
    };

    /**
     * Delete reason
     */
    const onDeleteReason = (reason: *) => {
        const doDeleteReason = () => {
            const reasons = progressStore.selectedReasons.filter(
                el => el.hash !== reason.hash,
            );
            onSubmit(reasons);
        };
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: "Bent u zeker dat u deze reden wil verwijderen?",
            primaryAction: doDeleteReason,
            primaryActionText: "Ja, verwijder",
            secondaryActionText: "Annuleren",
        });
    };

    /**
     * Reasons prevention when:
     *1- flow is aangvragen
     *2- selectedReasons has already one reason
     */
    const disableReasons =
        flow === "aanvragen" && progressStore.selectedReasons?.length === 1;

    const previousStepCompleted = progressStore.pages[0].isComplete;

    /**
     * Render
     */

    return (
        <Grid container>
            {previousStepCompleted ? (
                <Fragment>
                    <Grid
                        container
                        direction="row"
                        spacing={3}
                        alignItems="flex-start"
                        justify="space-between"
                        wrap="wrap"
                    >
                        <Grid item xs={12} md={4}>
                            <AddReason
                                onSubmit={handleSubmit}
                                initialValues={{
                                    inspectionReason: "",
                                    inspectionReasonDescription: null,
                                }}
                                id={id}
                                loading={loading}
                                disabled={disableReasons}
                                onReasonSelect={onReasonSelect}
                                reasons={reasons}
                                reasonsInInspection={
                                    progressStore.selectedReasons || []
                                }
                                single={flow === "aanvragen"}
                                loadReasons={loadReasons}
                            />
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <Box mb={3}>
                                <Typography type="headline6">
                                    {`Reeds toegevoegde ${
                                        flow === "aanvragen"
                                            ? "reden"
                                            : "redenen"
                                    }`}
                                </Typography>
                            </Box>
                            <ReasonsList
                                reasons={progressStore.selectedReasons}
                                onDelete={onDeleteReason}
                                onEdit={onEditReason}
                                flow={flow}
                            />
                        </Grid>
                        {!!reasonToEdit && (
                            <Modal
                                isOpen={!!reasonToEdit}
                                id={`${id}-mdlEditReason`}
                                title={reasonToEdit.shortDescription}
                                disableBackdropClick
                            >
                                <Box className={style.modalContent}>
                                    <AddReason
                                        onSubmit={handleSubmit}
                                        isEditMode={!!reasonToEdit}
                                        onClose={() => {
                                            setReasonToEdit(undefined);
                                            setCurrentReason({
                                                value: "",
                                                label: "",
                                            });
                                        }}
                                        initialValues={{
                                            inspectionReason: reasonToEdit.id,
                                            inspectionReasonDescription:
                                                reasonToEdit.comment,
                                        }}
                                        loading={loading}
                                        id={`${id}-mdlEditReasonContent`}
                                        onReasonSelect={onReasonSelect}
                                        reasons={reasons}
                                        reasonsInInspection={
                                            progressStore.selectedReasons || []
                                        }
                                        single={flow === "aanvragen"}
                                        embedded
                                        loadReasons={loadReasons}
                                    />
                                </Box>
                            </Modal>
                        )}
                    </Grid>
                </Fragment>
            ) : (
                <Redirect data={REDIRECTIONS.REASONS[flow.toUpperCase()]} />
            )}
        </Grid>
    );
};

export default DefineReason;
