// @flow

// libs
import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

//components
import Modal from "../Modal";
import PersonChip from "../PersonChip";
import PersonSelector from "../PersonSelector";

// types
import type { PersonModalType, Person } from "@types";

type Props = {
    id: string,
    title: string,
    isOpen: boolean,
    personModalType: PersonModalType,
    inspectionPointId?: string,
    onClose: () => void,
    onSelect: (type: PersonModalType, person: Person) => void,
    onRemove?: (type: PersonModalType, person: Person) => void,
    selectedPersons: ?(Person[]),
    saveButtonText?: string,
    showPills?: boolean,
    callInProgress?: boolean,
    single?: boolean,
    noChange?: boolean,
    filter?: Person[],
    onlyValidators?: boolean,
    onlyInspectors?: boolean,
    excludeCurrentUser?: boolean,
    hidePersonsForIp?: boolean,
    inspectorNames?: Array<any>,
};

/**
 * PersonsModal
 */
const PersonsModal = ({
    id,
    title,
    isOpen,
    personModalType,
    inspectionPointId,
    onClose,
    onSelect,
    onRemove,
    selectedPersons,
    saveButtonText = "Sluiten", // optional
    showPills, // optional
    callInProgress = false, // optional
    single, // optional
    noChange, // optional
    filter, // optional
    onlyValidators, // optional
    onlyInspectors, // optional
    excludeCurrentUser = false, // optional
    hidePersonsForIp = false, //optional
    inspectorNames,
}: Props) => {
    const personToChip = (type: PersonModalType, callInProgress: boolean) => (
        person: Person,
    ) => {
        const { id } = person;
        const typeForId = type.charAt(0).toUpperCase() + type.slice(1);
        const deleteHandler =
            callInProgress || !onRemove
                ? undefined
                : () => onRemove(type, person);
        return (
            <PersonChip
                person={person}
                key={id}
                id={`${id}-chp${typeForId}`}
                onDelete={deleteHandler}
            />
        );
    };

    return (
        <Modal isOpen={isOpen} id={`${id}-mdlChoosePerson`} title={title}>
            <Fragment>
                <Box p={3}>
                    {callInProgress && (
                        <LinearProgress style={{ marginBottom: "0.75em" }} />
                    )}
                    {showPills && (
                        <Box
                            mb={3}
                            mt={callInProgress ? 3 : undefined}
                            maxWidth="666px"
                        >
                            {selectedPersons &&
                                selectedPersons.map(
                                    personToChip(
                                        personModalType,
                                        callInProgress,
                                    ),
                                )}
                        </Box>
                    )}
                    <PersonSelector
                        id={`${id}-personSelector`}
                        onSelect={person => onSelect(personModalType, person)}
                        onRemove={person =>
                            onRemove && onRemove(personModalType, person)
                        }
                        inspectionPointId={inspectionPointId}
                        selectedPersons={selectedPersons}
                        callInProgress={callInProgress}
                        single={single}
                        noChange={noChange}
                        filter={filter}
                        onlyValidators={onlyValidators}
                        onlyInspectors={onlyInspectors}
                        excludeCurrentUser={excludeCurrentUser}
                        hidePersonsForIp={hidePersonsForIp}
                        inspectorNames={inspectorNames}
                        personModalType={personModalType}
                    />
                </Box>
                <Box
                    display="flex"
                    mt={2}
                    justifyContent="flex-end"
                    justifyItems="center"
                >
                    <Button
                        id={`${id}-mdlClosePersonModal`}
                        variant="contained"
                        color="primary"
                        onClick={onClose}
                        disableElevation
                    >
                        {saveButtonText}
                    </Button>
                </Box>
            </Fragment>
        </Modal>
    );
};

export default PersonsModal;
