// @flow
//Styles
import style from "../style.module.scss";
//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import classnames from "classnames";
import Link from "gatsby-link";
//Icons
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import HistoryIcon from "@material-ui/icons/History";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CommunicationIcon from "@material-ui/icons/Sms";

//Component
import DeadlineExtensionMarker from "../../../DeadlineExtensionMarker";
import Marker from "../../../Marker";
import ReactionsMarker from "../../../ReactionsMarker";
import StatusProgress from "../../../StatusProgress";
import Typography from "../../../Typography";
import ZvtZatCell from "../ZvtZatCell";
import AvatarRenderer from "../../../AvatarRenderer";

//Constants
import { PROCESS_STATUSES, INSPECTIONPOINTS, ASSIGNMENTS } from "@constants";
//Utils
import {
    displayDate,
    isArrayWithContent,
    Address,
    PersonUtil,
    // getReviewer,
    recordHasComplaint,
} from "@utils";
//Hooks
import { useUserRoleType } from "@hooks";

const ListToolTip = ({ list, mapper, fallback }: *) => {
    return isArrayWithContent(list) ? (
        <ul className={style.listToolTip}>{list.map(mapper)}</ul>
    ) : (
        `(${fallback})`
    );
};

type Props = {
    id: string,
    record: *,
    index: number,
    selection: *,
    renderReasonsToString: *,
    definition: Array<*>,
};

const AssignmentRow = ({
    record,
    index,
    selection,
    id,
    renderReasonsToString,
    definition,
}: Props) => {
    const hasComplaint = recordHasComplaint(record);

    const personToLi = (person, index) =>
        person ? (
            <li key={`${person.id}-${index}`}>{PersonUtil.display(person)}</li>
        ) : null;

    const visitToLi = (visit, index) =>
        visit ? <li key={`${visit}-${index}`}>{visit}</li> : null;

    const isExternalUser = useUserRoleType("ROLE_EXTERNAL");

    return (
        <TableRow
            id={`${id}-record`}
            key={index}
            hover
            tabIndex={-1}
            className={classnames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
                [style.selected]: selection === index,
            })}
        >
            <TableCell>
                <div className={style.advancedReferenceCell}>
                    {record.disclosureLevel && (
                        <AvatarRenderer
                            id={`${id}-disclosure`}
                            title={
                                ASSIGNMENTS.DISCLOSURE_LEVELS[
                                    record.disclosureLevel
                                ]
                            }
                            disclosureLevel={record.disclosureLevel}
                            className={style.smallAvatar}
                        />
                    )}
                    {record.reference ? (
                        <Link
                            to={`/opdrachten/${record.inspectionId}/inspectie-details`}
                            id={`${id}-link-inspection-details-${record.inspectionId}`}
                        >
                            <Typography
                                type="subtitle1Regular"
                                color={hasComplaint ? "error" : "dark"}
                            >
                                {record.reference}
                            </Typography>
                        </Link>
                    ) : (
                        "geen opdrachtnummer"
                    )}
                </div>
            </TableCell>

            <TableCell>
                <a
                    href={`/inspectiepunt/${record.inspectionPointId}/dossier`}
                    target="_blank"
                    rel="noopener noreferrer"
                    id={`${id}-record-${index}-inspectionPoint-dossier`}
                    className={style.inspectionPoints}
                >
                    <Tooltip
                        title={
                            <ListToolTip
                                list={record.visitInspectionPointNames}
                                mapper={visitToLi}
                                fallback="Geen andere locaties"
                            />
                        }
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            className={style.advancedLocation}
                        >
                            {record?.type && (
                                <Tooltip
                                    placement="top"
                                    title={
                                        INSPECTIONPOINTS.TYPE_LABELS[
                                            record.type
                                        ]
                                    }
                                >
                                    <Avatar
                                        className={style.smallAvatar}
                                        variant="rounded"
                                    >
                                        {INSPECTIONPOINTS.TYPE[record.type]}
                                    </Avatar>
                                </Tooltip>
                            )}
                            <Typography type="subtitle1">
                                {record.inspectionPointName}
                            </Typography>
                            {isArrayWithContent(
                                record.visitInspectionPointNames,
                            ) &&
                                record.visitInspectionPointNames.length > 1 && (
                                    <Box ml={0}>
                                        <Typography
                                            type="body1"
                                            color="disabled"
                                        >
                                            (
                                            {
                                                record.visitInspectionPointNames
                                                    .length
                                            }
                                            )
                                        </Typography>
                                    </Box>
                                )}
                        </Box>
                    </Tooltip>
                    {record.address && (
                        <Typography type="subtitle1Regular">
                            {Address.format(record.address)}
                        </Typography>
                    )}
                </a>
            </TableCell>

            <TableCell align="left">{record.externalId}</TableCell>

            <ZvtZatCell
                id={`tableCell-Zvt-Zat-${record.inspectionId}`}
                ZVT={record.institutionTypeNames}
                ZAT={record.activityTypeNames}
            />

            <TableCell align="left">
                <div className={style.limitedWidthCell}>
                    {isArrayWithContent(record.requests) && (
                        <Typography type="label" color="disabled">
                            Uit opdracht
                        </Typography>
                    )}

                    {isArrayWithContent(record.reasons) && (
                        <Tooltip
                            placement="bottom"
                            title={renderReasonsToString(record.reasons)}
                        >
                            <Box display="flex">
                                <Box
                                    mr={0}
                                    className={style.reasonsOverflowEllipsis}
                                >
                                    {renderReasonsToString(record.reasons, 2)}
                                </Box>
                                <Typography type="body1" color="disabled">
                                    ({record.reasons.length})
                                </Typography>
                            </Box>
                        </Tooltip>
                    )}
                    {isArrayWithContent(record.requests) && (
                        <Box mt={0}>
                            <Typography type="label" color="disabled">
                                Uit aanvragen
                            </Typography>
                            <Tooltip
                                placement="bottom"
                                title={renderReasonsToString(
                                    record.requests.map(req => req.reason),
                                )}
                            >
                                <Box display="flex">
                                    <Box
                                        mr={0}
                                        className={
                                            style.reasonsOverflowEllipsis
                                        }
                                    >
                                        {renderReasonsToString(
                                            record.requests.map(
                                                req => req.reason,
                                            ),
                                            2,
                                        )}
                                    </Box>
                                    <Typography type="body1" color="disabled">
                                        ({record.requests.length})
                                    </Typography>
                                </Box>
                            </Tooltip>
                        </Box>
                    )}
                </div>
            </TableCell>

            <TableCell align="left">
                <StatusProgress
                    id={record.inspectionId}
                    category={PROCESS_STATUSES.ASSIGNMENTS}
                    status={record.status}
                />
                {!isExternalUser ? (
                    <div className={style.extraInfoBlock}>
                        {record.attentionFlag && (
                            <a
                                href={`/opdrachten/${record.inspectionId}/verslagen`}
                                target="_blank"
                                rel="noopener noreferrer"
                                id={`${id}-record-${index}-verslagen`}
                                style={{ color: "black" }}
                            >
                                <Tooltip
                                    title="Aandachtsverslag aanwezig"
                                    placement="top"
                                    arrow
                                >
                                    <NotificationImportantIcon color="error" />
                                </Tooltip>
                            </a>
                        )}
                        <ReactionsMarker
                            id={`${id}-record-${index}`}
                            data={{
                                inspectionId: record.inspectionId,
                                reactionCount: record.reactionCount,
                                reportChangedByAnyReaction:
                                    record.reportChangedByAnyReaction,
                                unprocessedReactionsCount:
                                    record.unprocessedReactionsCount,
                            }}
                        />
                        {record.attachmentCount !== undefined &&
                        !isExternalUser &&
                        record.attachmentCount > 0 ? (
                            <a
                                href={`/opdrachten/${record.inspectionId}/bijlagen`}
                                target="_blank"
                                rel="noopener noreferrer"
                                id={`${id}-record-${index}-bijlagen`}
                                style={{ color: "black" }}
                            >
                                <Marker
                                    id={`${id}-record-${index}`}
                                    count={record.attachmentCount}
                                    Icon={AttachFileIcon}
                                    title="Bijlage, Bijlagen"
                                    ml="1"
                                />
                            </a>
                        ) : (
                            <Marker
                                id={`${id}-record-${index}`}
                                count={record.attachmentCount}
                                Icon={AttachFileIcon}
                                IconProps={{
                                    color: "disabled",
                                }}
                                title="Bijlage, Bijlagen"
                                ml="1"
                            />
                        )}
                        {record.internalCommunicationCount !== undefined &&
                        !isExternalUser &&
                        record.internalCommunicationCount > 0 ? (
                            <a
                                href={`/opdrachten/${record.inspectionId}/interne-communicatie`}
                                target="_blank"
                                rel="noopener noreferrer"
                                id={`${id}-record-${index}-interne-communicatie`}
                                style={{ color: "black" }}
                            >
                                <Marker
                                    id={`${id}-record-${index}`}
                                    count={record.internalCommunicationCount}
                                    color="default"
                                    Icon={CommunicationIcon}
                                    title="Interne Communicatie, Interne Communicaties"
                                    ml="1"
                                />
                            </a>
                        ) : (
                            <Marker
                                id={`${id}-record-${index}`}
                                count={record.internalCommunicationCount}
                                Icon={CommunicationIcon}
                                IconProps={{
                                    color: "disabled",
                                }}
                                title="Interne Communicatie, Interne Communicaties"
                                ml="1"
                            />
                        )}

                        <DeadlineExtensionMarker
                            id={`${id}-record-${index}`}
                            status={record.status}
                            draftReportDeadlineExtension={
                                record.draftReportDeadlineExtension
                            }
                            finalReportDeadlineExtension={
                                record.finalReportDeadlineExtension
                            }
                        />
                        <a
                            href={`/opdrachten/${record.inspectionId}/historiek`}
                            target="_blank"
                            rel="noopener noreferrer"
                            id={`${id}-record-${index}-historiek`}
                        >
                            <Tooltip title="Historiek">
                                <HistoryIcon />
                            </Tooltip>
                        </a>
                    </div>
                ) : null}
            </TableCell>

            <TableCell>{displayDate(record.targetDate)}</TableCell>

            {definition &&
                definition.some(col => col.id === "last-visit-done") && (
                    <TableCell>
                        {!record.shouldHaveVisitMoments
                            ? "Geen bezoek"
                            : record.lastVisitDoneOn
                            ? displayDate(record.lastVisitDoneOn)
                            : "Niet ingevuld"}
                    </TableCell>
                )}

            {definition &&
                definition.some(col => col.id === "last-report-sent") && (
                    <TableCell>
                        {displayDate(record.lastReportSentOn)}
                    </TableCell>
                )}
            {!isExternalUser ? (
                <TableCell align="left">
                    <Tooltip
                        placement="bottom"
                        title={
                            <ListToolTip
                                list={record.inspectors}
                                mapper={personToLi}
                                fallback="Geen andere inspecteurs toegewezen"
                            />
                        }
                    >
                        <Box>
                            {record.leadInspector && (
                                <Box mb={0} display="flex" alignItems="center">
                                    <Typography type="subtitle1Regular">
                                        {PersonUtil.display(
                                            record.leadInspector,
                                        )}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Tooltip>
                </TableCell>
            ) : null}
            {!isExternalUser ? (
                <TableCell align="left">
                    {PersonUtil.display(record.assignee)}
                </TableCell>
            ) : null}
        </TableRow>
    );
};

export default AssignmentRow;
