//@flow
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import style from "./style.module.scss";

import { load as loadFormsSummary } from "@stores/forms-summary";
import { formsScheduleActivation as activateFormVersion } from "@stores/forms-schedule-activation";
import { formsScheduleDeactivation as deActivateFormVersion } from "@stores/forms-schedule-deactivation";
import { formsScheduleCancel as cancelFormVersion } from "@stores/forms-schedule-cancel";
import { formsImport as importForms } from "@stores/forms-import";

import { AdminList } from "@components/Admin";
import { Table, LoadingButton } from "@components/Shared";
import Typography from "@components/Shared/Typography";
import { VersionControlModal } from "@components/Admin";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import { reportTypesVersions as reportTypesVersionsDefinition } from "../../definitions";
import { ROWRENDERERCONST } from "@constants";
import { displayDate, convertDateForAPIReversed } from "@utils/date";
import { REPORTITEM } from "@constants";

/* Types */
type ActionType = "ACTIVATE" | "DEACTIVATE" | "CANCEL";

const labelsSearch = (item: *, query: string) =>
    item.name.toLowerCase().includes(query.toLowerCase());

const getRowHeight = ({ name }: *): number =>
    48 + Math.floor(name.length / 32) * 24;

/* Selectors */
const formsSummarySelector = state => state.formsSummary;
const formsImportLoadingSelector = state => state.formsImport.loading;

/**
 * Component
 */
const AdminVersiebeheer = ({ id }: *) => {
    const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedReportType, setSelectedReportType] = useState<any>(null);
    const [showVersionControlModal, toggleVersionControlModal] = useState(
        false,
    );
    const [modalAction, setModalAction] = useState<ActionType>("ACTIVATE");
    const [modalValue, setModalValue] = useState<any>(null);
    const formsSummaryStore = useSelector(formsSummarySelector);
    const formsImportLoading = useSelector(formsImportLoadingSelector);

    const findObjectById = (array, id) => {
        const result = array.find(item => item.id === id);
        return result ? result : `No object found with id: ${id}`;
    };

    useEffect(() => {
        dispatch(loadFormsSummary());
        selectedReportType &&
            setSelectedReportType(
                findObjectById(
                    formsSummaryStore.data.forms,
                    selectedReportType.id,
                ),
            );
    }, [dispatch, formsSummaryStore?.data?.forms, selectedReportType?.id]);

    const { lastImport, lastSuccessfulImport, forms } =
        formsSummaryStore?.data || {};

    const handleSelectReportType = (item: *, index: number) => {
        setSelectedIndex(index);
        setSelectedReportType({ ...item });
    };
    const handleFetchVersions = () => {
        dispatch(importForms());
    };
    const handleEditVersion = (action: ActionType, record: *) => {
        setModalAction(action);
        setModalValue(record);
        toggleVersionControlModal(true);
    };

    const handleSubmit = (value: *) => {
        if (modalAction === "ACTIVATE") {
            dispatch(
                activateFormVersion({
                    activationDate: convertDateForAPIReversed(value.formDate),
                    formId: selectedReportType.id,
                    remark: value.remark || "",
                    versionId: value.id,
                    versionName: value.versionName || "",
                }),
            );
        }
        if (modalAction === "DEACTIVATE") {
            dispatch(
                deActivateFormVersion({
                    deactivationDate: convertDateForAPIReversed(value.formDate),
                    formId: selectedReportType.id,
                    remark: value.remark || "",
                    versionId: value.id,
                    versionName: value.versionName || "",
                }),
            );
        }
        if (modalAction === "CANCEL") {
            dispatch(
                cancelFormVersion({
                    formId: selectedReportType.id,
                    versionId: value.id,
                }),
            );
        }
        toggleVersionControlModal(false);
    };

    const hasMultipleActiveVersions = versions => {
        const activeCount =
            versions &&
            versions.reduce(
                (count, version) =>
                    count + (version.activationStatus === "ACTIVE" ? 1 : 0),
                0,
            );

        return activeCount > 1;
    };

    const canDeactivateVersions = hasMultipleActiveVersions(
        selectedReportType?.versions,
    );
    return (
        <Box display="flex">
            <VersionControlModal
                id={`${id}-mdlVersionControl`}
                isOpen={showVersionControlModal}
                onSave={handleSubmit}
                onCancel={() => toggleVersionControlModal(false)}
                versionControlData={modalValue}
                action={modalAction}
            />
            <Box className={style.listBlock}>
                <LoadingButton
                    id={`${id}-btnFetchVersions`}
                    variant="contained"
                    color="primary"
                    onClick={handleFetchVersions}
                    className={style.fetchButton}
                    loading={formsImportLoading}
                    fullWidth
                >
                    Versies ophalen
                </LoadingButton>

                <AdminList
                    id={`${id}-lstReportTypes`}
                    list={forms}
                    onSelect={handleSelectReportType}
                    selectedIndex={selectedIndex}
                    loading={formsSummaryStore.loading}
                    renderLabel={item => item.name}
                    searchFunction={labelsSearch}
                    rowHeight={getRowHeight}
                    height={480}
                />
            </Box>
            {selectedReportType && (
                <Box className={style.formBlock}>
                    <Paper className={style.wrapper}>
                        <Typography type="body2">
                            <span className={style.underline}>
                                Datum laatst geslaagde import:
                            </span>{" "}
                            {lastSuccessfulImport &&
                                displayDate(lastSuccessfulImport?.importTime)}
                        </Typography>
                        <Typography type="body2">
                            <span className={style.underline}>
                                Status laatste import:
                            </span>{" "}
                            {lastImport &&
                                REPORTITEM.IMPORT_STATUS[
                                    lastImport.importStatus
                                ]}
                        </Typography>
                    </Paper>
                    <Table
                        id={`${id}-table`}
                        rows={selectedReportType.versions}
                        rowRenderer={ROWRENDERERCONST.REPORT_TYPES_VERSIONS}
                        definition={reportTypesVersionsDefinition}
                        loading={selectedReportType.loading}
                        onEdit={handleEditVersion}
                        totalRows={selectedReportType?.versions?.length || 0}
                        noData="Er zijn nog geen versies beschikbaar voor dit reporttype"
                        hidePagination
                        canActivateVersions={true}
                        canDeactivateVersions={canDeactivateVersions}
                        canCancelVersions={true}
                    />
                </Box>
            )}
        </Box>
    );
};

export default AdminVersiebeheer;
